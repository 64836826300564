import { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Messages from "../../lang/Messages";
import { FormDataContext } from "../../App";
import { useNavigate } from "react-router-dom";
import {
  CircularProgress,
  ClickAwayListener,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from "@mui/material";
import "./Association.scss";
import { SelectionDetail } from "../Selections/SelectionDetail";
import { createContact, getIpAddress } from "../../services/endPoints";
import { Product } from "../Types";
import { ButtonLoader } from "../Commons/ButtonLoader";
import { getFilteredProducts } from "../Utils/getAPIData";

const requireFields = ["name", "lastName", "email", "phoneNumber"];

export const Association = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate()
  const { setIsCheckout } = useContext(FormDataContext);

  const [contactData, setContactData] = useState<Record<string, string>>(
    {} as Record<string, string>
  );
  const [errors, setErrors] = useState<Record<string, string>>(
    {} as Record<string, string>
  );
  const [currentProducts, setCurrentProducts] = useState<Product[]>(
    [] as Product[]
  );
  const [selectedProduct, setSelectedProduct] = useState<Product>(
    {} as Product
  );
  const [isWaiting, setIsWaiting] = useState<boolean>(true);
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<Boolean>(false);

  useEffect(() => {
    setIsCheckout(false);
    const fetchProducts = async () => {
      try {
        const newProducts = await getFilteredProducts()
        setCurrentProducts(newProducts.filter(product => product.isActive))
        setIsWaiting(false)
      }
      catch {
        setIsWaiting(false)
      };
    }

    fetchProducts()
  }, [])

  const isValidForm = () => {
    return !requireFields.filter((field) => !contactData[field as keyof Record<string, string>]).length;
  };

  const handleError = (keyError: string, newValue: any, message: string) => {
    let newError = "";
    if (
      !newValue ||
      (!!newValue && typeof newValue === "string" && !newValue.trim())
    )
      newError = message;

    setErrors({ ...errors, [keyError]: newError });
  };

  const handleSelectionChange = (product: Product) => {
    setShowDetail(true);
    setSelectedProduct(product);
  };

  const handleTooltipClose = (ev: MouseEvent | TouchEvent) => {
    setShowDetail(false);
    //setSelectedProduct({} as Product)
  };

  const handlePayment = () => {
    sessionStorage.setItem("f_data", JSON.stringify(contactData));
    const { name, lastName, phoneNumber, email } = contactData
    getIpAddress()
    .then((response) => {
      createContact({ 
        email,
        firstname: name,
        lastname: lastName,
        phone: phoneNumber,
        ipAddress: response.data.ip,
        stage: 3
      }).then((response: any) => {
        const contactId = response.data.contact.id
        sessionStorage.setItem("c_id", contactId)
        navigate(`/${Messages.route_checkout.id}`, { state: { currentStep: 1, selectedProduct } })
      })
    })
    .catch((error) => {
      throw "Error getting IP"
    });
    
  };

  const { name, lastName, email, address, phoneNumber } = contactData;

  return isWaiting ? (
    <CircularProgress />
  ) : (
    <div className="register__container container">
      <div className="register__img">
        <h3 className="register__title">
          {formatMessage(Messages.register_title)}
        </h3>
        <h3 className="register__subtitle">
          {formatMessage(Messages.register_subtitle)}
        </h3>
      </div>

      <div className="register__form">
        <FormControl>
          <FormLabel id="radio-selections-group-label">
            {formatMessage(Messages.register_form_selections)}
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="radio-selections-group-label"
            name="radio-selections-group"
          >
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <div>
                {currentProducts.map((product) => {
                  const { categoryCode } = product;
                  return (
                    <Tooltip
                      open={
                        showDetail &&
                        selectedProduct.categoryCode === categoryCode
                      }
                      PopperProps={{ disablePortal: true }}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={
                        <SelectionDetail product={product} isResume={true} />
                      }
                    >
                      <FormControlLabel
                        value={categoryCode}
                        control={<Radio />}
                        label={formatMessage(
                          Messages[
                            `register_${categoryCode.toLowerCase()}` as keyof typeof Messages
                          ]
                        )}
                        onClick={() => handleSelectionChange(product)}
                      />
                    </Tooltip>
                  );
                })}
              </div>
            </ClickAwayListener>
          </RadioGroup>
        </FormControl>
        <TextField
          id="name-input"
          label={formatMessage(Messages.register_form_name)}
          value={name}
          variant="outlined"
          required={true}
          fullWidth
          placeholder={formatMessage(Messages.register_form_name)}
          error={!!errors.nameError}
          helperText={formatMessage(Messages.register_form_name_label)}
          onChange={(event) => {
            const { value } = event.target;
            setContactData({ ...contactData, name: value || "" });
            handleError(
              "nameError",
              value,
              formatMessage(Messages.register_form_name_error)
            );
          }}
        />
        <TextField
          id="lastName-input"
          label={formatMessage(Messages.register_form_lastName)}
          value={lastName}
          variant="outlined"
          required={true}
          fullWidth
          placeholder={formatMessage(Messages.register_form_lastName)}
          error={!!errors.lastNameError}
          helperText={formatMessage(Messages.register_form_lastName_label)}
          onChange={(event) => {
            const { value } = event.target;
            setContactData({ ...contactData, lastName: value || "" });
            handleError(
              "lastNameError",
              value,
              formatMessage(Messages.register_form_lastName_error)
            );
          }}
        />
        <TextField
          id="email-input"
          type="email"
          label={formatMessage(Messages.register_form_email)}
          value={email}
          variant="outlined"
          required={true}
          fullWidth
          placeholder={formatMessage(Messages.register_form_email)}
          error={!!errors.emailError}
          helperText={formatMessage(Messages.register_form_email_label)}
          onChange={(event) => {
            const { value } = event.target;
            setContactData({ ...contactData, email: value || "" });
            handleError(
              "emailError",
              value,
              formatMessage(Messages.register_form_email_error)
            );
          }}
        />
        <TextField
          id="phone-input"
          type="number"
          label={formatMessage(Messages.contact_phone_label)}
          value={phoneNumber}
          variant="outlined"
          required={true}
          fullWidth
          placeholder={formatMessage(Messages.contact_phone)}
          helperText={formatMessage(Messages.contact_phone_label)}
          onChange={(event) => {
            const { value } = event.target;
            setContactData({ ...contactData, phoneNumber: value || "" });
          }}
        />
      </div>
      <div className="register__payment">
        <h3>{formatMessage(Messages.register_payment_title)}</h3>
        <Divider />
        <img src='mercadopago.svg' alt='mercadopago.svg' />
        <Divider />
      </div>
      <ButtonLoader isValidForm={isValidForm()} save={handlePayment} isSaving={isSaving} buttonText={formatMessage(Messages.association_button)} />
      <Divider />
    </div>
  );
};
