import { useEffect, useState, useContext } from "react";
import { useIntl } from "react-intl";
import Messages from "../../lang/Messages";
import "./AboutClub.scss";
import { Divider } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import cn from "classnames";
import { FormDataContext } from "../../App";

const SELECTION_WORDINGS_IDS = [
  "about_exclusive_wine",
  "about_productor",
  "about_price",
];

const BOX_WORDINGS_IDS = [
  "about_box_reserved",
  "about_box_variety",
  "about_box_alternative",
  "about_box_change",
  "about_box_add",
];

const ABOUT_WORDINGS_IDS = [
  { messageId: "about_club_commit", countDetail: 1 },
  { messageId: "about_club_associate", countDetail: 2 },
  { messageId: "about_club_payments", countDetail: 1 },
  { messageId: "about_club_shipment", countDetail: 3 },
];

export const AboutClub = () => {
  const { formatMessage } = useIntl();
  const { setIsCheckout } = useContext(FormDataContext);

  const [showDetail, setShowDetail] = useState<boolean[]>([]);
  const [showDetailBoxes, setShowDetailBoxes] = useState<boolean[]>([]);

  useEffect(() => {
    setIsCheckout(false);
    const descriptions = document.getElementsByClassName("about_description");
    setShowDetail(Array.from(Array(descriptions.length), () => false));
  }, []);

  const handleShowDetail = (ev: React.MouseEvent) => {
    const index = parseInt(ev.currentTarget.id) - 1;
    const details = Array.from(Array(showDetail.length), () => false);

    setShowDetail([
      ...details.slice(0, index),
      !showDetail[index],
      ...details.slice(index + 1),
    ]);
  };

  const handleShowBoxDetail = (ev: React.MouseEvent) => {
    const index = parseInt(ev.currentTarget.id) - 1;
    const details = Array.from(Array(showDetailBoxes.length), () => false);

    setShowDetailBoxes([
      ...details.slice(0, index),
      !showDetailBoxes[index],
      ...details.slice(index + 1),
    ]);
  };

  return (
    <div className="about__container container">
      <div className="about__img">
        <p className="main__text">{formatMessage(Messages.about_title)}</p>
        <p className="secondary__text">
          {formatMessage(Messages.about_subtitle)}
        </p>
      </div>
      <div className="about__wines">
        <p className="main__text">
          {formatMessage(Messages.about_title_guide)}
        </p>
        <div className="about__guide">
          {SELECTION_WORDINGS_IDS.map((messageId, index) => (
            <>
              <div className="about_description" id={(index + 1).toString()} onClick={(ev) => handleShowDetail(ev)}>
                <div>
                  {formatMessage(Messages[messageId as keyof typeof Messages])}
                </div>
                <ArrowForwardIosIcon />
              </div>
              <p
                className={cn("about__detail", {
                  show__detail: showDetail[index],
                })}
              >
                {formatMessage(
                  Messages[`${messageId}_detail` as keyof typeof Messages]
                )}
              </p>
              <Divider />
            </>
          ))}
        </div>
      </div>
      <div className="about__boxes">
        <div className="about__title">
          <p className="main__text">
            {formatMessage(Messages.about_box_title)}
          </p>
          <p className="secondary__text">
            {formatMessage(Messages.about_box_subtitle)}
          </p>
        </div>
        <div className="about__guide">
          {BOX_WORDINGS_IDS.map((messageId, index) => (
            <>
              <div className="about_description" id={(index + 1).toString()} onClick={(ev) => handleShowBoxDetail(ev)}>
                <div>
                  {formatMessage(Messages[messageId as keyof typeof Messages])}
                </div>
                <ArrowForwardIosIcon />
              </div>
              <p
                className={cn("about__detail", {
                  show__detail: showDetailBoxes[index],
                })}
              >
                {formatMessage(
                  Messages[`${messageId}_detail` as keyof typeof Messages]
                )}
              </p>
              <Divider />
            </>
          ))}
        </div>
      </div>
      <div className="about__club">
        <img src="people.jpg" alt="" />
        <div className="about__text__container">
          <div className="about__texts">
            {ABOUT_WORDINGS_IDS.map(({ messageId, countDetail }, index) => {
              const detailsIndex = Array.from(
                { length: countDetail },
                (_, index) => index
              );
              return (
                <div className="about__text">
                  <div className="texts">
                    <h3>
                      {formatMessage(
                        Messages[messageId as keyof typeof Messages]
                      )}
                    </h3>
                    {detailsIndex.map((key) => (
                      <div className="about__info">
                        <p>
                          {formatMessage(
                            Messages[
                              `${messageId}_subtitle${
                                key + 1
                              }` as keyof typeof Messages
                            ]
                          )}
                        </p>
                        <p>
                          {formatMessage(
                            Messages[
                              `${messageId}_detail${
                                key + 1
                              }` as keyof typeof Messages
                            ]
                          )}
                        </p>
                      </div>
                    ))}
                  </div>
                  {ABOUT_WORDINGS_IDS.length > index + 1 && <Divider />}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
