import React, { useState, useRef, useEffect, ReactElement } from "react";
import "./Carousel.scss";
import { Product } from "../Types";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

type CarouselProps = {
  items: Product[];
  children?: ReactElement;
  currentIndex: number;
};

export const CarouselWrapper = ({ items, children, currentIndex }: CarouselProps) => {

  return (
    <Carousel>
      {items.map((item) => {
        if(children) {
          const childWithProps = React.cloneElement(children, { ...children.props, item });
          return (<div>{childWithProps}</div>);
        }
        const carousel = document.getElementsByClassName('carousel-slider')
        if(carousel[currentIndex]) {
         const rightValue = carousel[currentIndex].clientWidth > 700 ? '180px' : '60px'  
         const prevControl = carousel[currentIndex].getElementsByClassName('control-prev')[0] as HTMLElement | undefined
         const nextControl = carousel[currentIndex].getElementsByClassName('control-next')[0] as HTMLElement | undefined
         if(prevControl && nextControl) {
          prevControl.style.right =  rightValue
          nextControl.style.right =  rightValue
         }
        }

        
        return (<img src='item.imageUrl' alt={item.imageUrl} />)
      })}
    </Carousel>
  );
};
