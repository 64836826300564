import { useIntl } from "react-intl";
import Messages from "../../lang/Messages";
import { CircularProgress, TextField } from "@mui/material";
import { useState } from "react";
import { Locality } from "../Types";
import { createLocality } from "../../services/endPoints";
import "./LocalityForm.scss";

const requireFields = ["name", "province", "cost"];

type LocalityFormProps = {
  isSaving: Boolean;
  newLocality: Locality;
  setNewLocality: (locality: Locality) => void;
  handleSaveLocality: () => void;
};

export const LocalityForm = ({ isSaving, newLocality, setNewLocality, handleSaveLocality }: LocalityFormProps) => {
  const { formatMessage } = useIntl();

  const [errors, setErrors] = useState<Record<string, string>>(
    {} as Record<string, string>
  );

  const validateForm = () => {
    return !requireFields.filter(
      (field) => !newLocality[field as keyof Locality]
    ).length;
  };

  const handleLocalityChange = (key: string | number, value: string) => {
    setNewLocality({ ...newLocality, [key]: value });
  };

  const handleError = (keyError: string, newValue: any, message: string) => {
    let newError = "";
    if (
      !newValue ||
      (!!newValue && typeof newValue === "string" && !newValue.trim())
    )
      newError = message;

    setErrors({ ...errors, [keyError]: newError });
  };

//   const handleSaveLocality = () => {
//     setIsSaving(true);
//     createLocality(newLocality)
//       .then(() => {
//         setNewLocality({} as Locality);
//         setIsSaving(false);
//       })
//       .catch(() => {
//         setIsSaving(false);
//       });
//   };

  return (
    <>
      {isSaving && <CircularProgress />}
      <div className="locality__form">
        <TextField
          fullWidth
          label={formatMessage(Messages.locality_name)}
          variant="outlined"
          error={!!errors.nameError}
          value={newLocality.name || ""}
          onChange={(event) => {
            const { value } = event.target;
            handleLocalityChange("name", value);
            handleError(
              "nameError",
              value,
              formatMessage(Messages.locality_name_error)
            );
          }}
          onBlur={(event) => {
            const { value } = event.target;
            handleError(
              "nameError",
              value,
              formatMessage(Messages.locality_name_error)
            );
          }}
        />
        <TextField
          fullWidth
          label={formatMessage(Messages.locality_province)}
          variant="outlined"
          error={!!errors.provinceError}
          value={newLocality.province || ""}
          onChange={(event) => {
            const { value } = event.target;
            handleLocalityChange("province", value);
            handleError(
              "provinceError",
              value,
              formatMessage(Messages.locality_province_error)
            );
          }}
          onBlur={(event) => {
            const { value } = event.target;
            handleError(
              "nameError",
              value,
              formatMessage(Messages.locality_name_error)
            );
          }}
        />
        <TextField
          fullWidth
          label={formatMessage(Messages.locality_description)}
          variant="outlined"
          value={newLocality.description || ""}
          onChange={(event) => {
            const { value } = event.target;
            handleLocalityChange("description", value);
          }}
        />
        <TextField
          fullWidth
          label={formatMessage(Messages.locality_cost)}
          variant="outlined"
          value={newLocality.cost || ""}
          error={!!errors.costError}
          onChange={(event) => {
            const { value } = event.target;
            handleLocalityChange("cost", value);
            handleError(
              "costError",
              value,
              formatMessage(Messages.locality_cost_error)
            );
          }}
          onBlur={(event) => {
            const { value } = event.target;
            handleError(
              "costError",
              value,
              formatMessage(Messages.locality_cost_error)
            );
          }}
        />
        <div className="save__button">
          <button disabled={!validateForm()} onClick={handleSaveLocality}>
            {formatMessage(Messages.product_save)}
          </button>
        </div>
      </div>
    </>
  );
};
