import { useIntl } from "react-intl";
import _ from "lodash";
import Messages from "../../lang/Messages";
import { useEffect, useState } from "react";
import { Divider } from "@mui/material";
import "./Reviews.scss";

const REVIEWS = [
  {
    text: "Ser miembro del club me abrió las puertas a descubrir el mundo del vino desde una perspectiva completamente innovadora y alternativa. Todos los meses recibo vinos de una calidad superadora a un precio muy competitivo, ademas de la oportunidad de participar de eventos sumamemente interesantes conociendo personas que comparten mi pasión\n\nMartin R., socio desde 2013",
    points: 5,
  },
  {
    text: "Todos los meses Unico Club te sorprende con una variedad de vinos super exclusivos, etiquetas que no encontrás en otros lugares y realizadas por las mejores bodegas del país. Cuentan con un equipo de profesionales con altísimo conocimiento en la materia y que te permiten aventurarte en un mundo nuevo del vino\n\nRocío F., socia desde 2012",
    points: 5,
  },
  {
    text: "Me encanta recibir todos los meses mi caja con las selecciones, nunca dejan de sorprenderme.\n Totalmente recomendados!\n\nGonzalo F., socio desde 2015",
    points: 4,
  }
];

const STARTS = [0, 1, 2, 3, 4];

export const Reviews = () => {
  const { formatMessage } = useIntl();

  const [reviews, setReviews] = useState<Record<string, string | number>[]>([]);

  useEffect(() => {
    setReviews(_.chunk(REVIEWS, 3)[0]);
  }, []);

  const showMore = () => {};

  return (
    <div className="reviews__container">
      <h3>{formatMessage(Messages.reviews__title)}</h3>
      <div className="reviews">
        {reviews.map(({ text, points }, index) => (
          <>
            <div className="review">
              <div className="star__review">
                {STARTS.map((index) => (
                  <>
                    {index + 1 <= (points as number) ? (
                      <img src='filledStar.svg' alt='filledStar.svg' />
                    ) : (
                      <img src='emptyStart.svg' alt="emptyStart.svg" />
                    )}
                  </>
                ))}
              </div>
              <p>{text}</p>
            </div>
            {index < reviews.length - 1 && <Divider />}
          </>
        ))}
      </div>
      {/* <button onClick={showMore}>
        {formatMessage(Messages.reviews__button)}
      </button> */}
    </div>
  );
};
