import { useEffect, useState, useContext } from "react";
import { useIntl } from "react-intl";
import Messages from "../../../lang/Messages";
import "./Confirmation.scss";
import { FormDataContext } from "../../../App";
import { createOrder } from "../../../services/endPoints";

type ConfirmationProps = {
  formData?: Record<string, string>;
}

export const Confirmation = ( { formData }: ConfirmationProps) => {
  const { formatMessage } = useIntl();
  const { setIsCheckout } = useContext(FormDataContext);

  const { email, completeName, address, postalCode, city, province } = formData || {};

  useEffect(() => {
    setIsCheckout(false);
    // sessionStorage.removeItem('s_current')
    // sessionStorage.removeItem('f_data')
  }, [])

  const handleChange = () => {};

  return (
    <div className="confirmation__container">
      <h2>{formatMessage(Messages.confirmation_title)}</h2>
      <div className="confirmation">
        <div className="confirmation__info">
          <h3>{formatMessage(Messages.confirmation_sent)}</h3>
          <div className="confirmation__detail">
            <div className="confirmation__data">
              <div className="data__change">
                <p>{formatMessage(Messages.confirmation_email)}</p>
                <p className="data__button" onClick={handleChange}>{formatMessage(Messages.confirmation_change)}</p>
              </div>
              <p className="data__value">{email}</p>
            </div>
            <div className="confirmation__data">
              <div className="data__change">
                <p>{formatMessage(Messages.confirmation_method)}</p>
                <p className="data__button">{formatMessage(Messages.confirmation_change)}</p>
              </div>
              <p className="data__value">{formatMessage(Messages.confirmation_method_value)}</p>
            </div>
            <div className="confirmation__data">
              <div className="data__change">
                <p>{formatMessage(Messages.confirmation_to)}</p>
                <p className="data__button">{formatMessage(Messages.confirmation_change)}</p>
              </div>
              <p className="data__value">{completeName}</p>
            </div>
            <div className="confirmation__data">
              <div className="data__change">
                <p>{formatMessage(Messages.confirmation_address)}</p>
                <p className="data__button">{formatMessage(Messages.confirmation_change)}</p>
              </div>
              <p className="data__value">{`${address} ${city} ${province} ${postalCode}`}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
