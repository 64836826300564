import { TextField } from "@mui/material";
import { FormFields } from "../Types";

const LOGIN_FIELDS = [
  {
    id: "username",
    component: TextField,
    type: "text",
    label: "Email",
    error: "Debe ingresar un email",
    helperText: "Email",
    required: true,
    additionalAttributes: {
      fullWidth: true,
    },
  },
  {
    id: "password",
    component: TextField,
    type: "password",
    label: "Contraseña",
    error: "Debe ingresar una contraseña",
    helperText: "Contraseña",
    required: true,
    additionalAttributes: {
      fullWidth: true,
    },
  },
];

const REGISTER_FIELDS = [
  {
    id: "email",
    component: TextField,
    type: "text",
    label: "Email",
    error: "Debe ingresar un email",
    helperText: "Email",
    required: true,
    additionalAttributes: {
      fullWidth: true,
    },
  },
  {
    id: "password",
    component: TextField,
    type: "password",
    label: "Contraseña",
    error: "Debe ingresar una contraseña",
    helperText: "Contraseña",
    required: true,
    additionalAttributes: {
      fullWidth: true,
    },
  },
  {
    id: "confirmPassword",
    component: TextField,
    type: "password",
    label: "Confirmar contraseña",
    error: "Debe ingresar una contraseña",
    helperText: "Confirmar contraseña",
    required: true,
    additionalAttributes: {
      fullWidth: true,
    },
  },
];

const getLoginFields = (): FormFields[] => {
  return LOGIN_FIELDS;
};

const getRegisterFields = (): FormFields[] => {
  return REGISTER_FIELDS;
};

export { getLoginFields, getRegisterFields };
