import { useIntl } from "react-intl";
import _ from "lodash";
import Messages from "../../lang/Messages";
import { Pack, Product } from "../Types";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import "./Wines.scss";
import { Skeleton } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { getFilteredProducts } from "../Utils/getAPIData";

type ProductCarouselProps = {
  currentProducts: Product[];
};

const ShowProductsCarousel = ({ currentProducts }: ProductCarouselProps) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  return (
    <>
      {currentProducts.map(
        ({ imageUrl, pack, categoryCode }, index) => (
          <div className="wine__card">
            {/* <div className="wine__month">
              {formatMessage(
                Messages[
                  `wines_${getMonthText(endDate)}` as keyof typeof Messages
                ]
              )}
            </div> */}
            <div className="wine__month">
              {formatMessage(
                Messages[
                  `product_${categoryCode.toLowerCase()}` as keyof typeof Messages
                ]
              )}
            </div>
            <div className="wine__image">
              <img src={imageUrl} alt={`${imageUrl}`} />
            </div>
            <div className="wine__detail">
              {pack.map(({ quantity, type }: Pack) => (
                <p>{`${quantity} ${type}`}</p>
              ))}
            </div>
            <button
              onClick={() =>
                navigate(`/${Messages.route_selection_detail.id}`, {
                  state: currentProducts[index],
                })
              }
            >
              {formatMessage(Messages.wines_button)}
            </button>
          </div>
        )
      )}
    </>
  );
};

export const WinesDesktop = () => {
  const { formatMessage } = useIntl();

  const [isWaiting, setIsWaiting] = useState<Boolean>(true);
  const [currentProducts, setCurrentProducts] = useState<Product[][]>([[],] as Product[][]);
  
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const newProducts = await getFilteredProducts()
        setCurrentProducts(
          _.chunk(
            newProducts.sort((prod1, prod2) =>
              prod1.categoryCode < prod2.categoryCode ? 1 : -1
            ),
            3
          )
        );
        setIsWaiting(false)
      }
      catch {
        setIsWaiting(false)
      };
    }

    fetchProducts()
  }, [])

  // useEffect(() => {
  //   products.findIndex(
  //     ({ endDate }) =>
  //       getMonthText(endDate) === moment().format("MMM").toLowerCase()
  //   );
  //   const prodGroups = _.chunk(products, 3);
  //   const prodGroupsMaxIndex = prodGroups.length - 1

  //   prodGroups.forEach((prods, index) => {
  //     const findIndex = prods.findIndex(
  //       ({ endDate }) =>
  //         getMonthText(endDate) === moment().format("MMM").toLowerCase()
  //     );
  //     if(findIndex >= 0) {
  //       setLastIndex(index)
  //       setIsLastIndex(index === prodGroupsMaxIndex)
  //       setIsFirstIndex(index === 0)
  //       setCurrentProducts(prods)
  //     }
  //   });
  // }, [products]);

  const associate = () => {
    window.mt("send", "pageview", { page_title: "associate" });
  };

  return (
    <div className="wines__container">
      <h3>{formatMessage(Messages.wines_title)}</h3>
      <div className="wines__selection">
        <div className="wine__container">
          {/* <ArrowBackIosIcon
            className={cn("arrow", { active: !isFirstIndex })}
            onClick={() => handleProductsToShow()}
          /> */}
          {isWaiting ? (
            <Skeleton variant="rectangular" width={window.innerWidth * 0.8} height={460} />
          ) : (
            <Carousel>
              {currentProducts.map((products) => (
                <ShowProductsCarousel currentProducts={products} />
              ))}
            </Carousel>
          )}
          {/* <ArrowForwardIosIcon
            className={cn("arrow", { active: !isLastIndex })}
            onClick={() => handleProductsToShow(true)}
          /> */}
        </div>
        {/* <div className="wine__selector">
          {currentProducts[lastIndex] &&
            currentProducts[lastIndex].map((_, index) => (
              <div
                className={cn("selector", { active: lastIndex === index })}
                onClick={() => showDirectProducts(index)}
              ></div>
            ))}
        </div> */}
      </div>
    </div>
  );
};
