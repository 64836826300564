import { useEffect, useContext, useState } from "react";
import "../Resume.scss";
import { FormDataContext } from "../../../App";
import { createOrder, createSubscription, editContactAddress } from "../../../services/endPoints";
import "./Payment.scss";
import { Product, StepComponent } from "../../Types";
import { CardPayment, initMercadoPago } from "@mercadopago/sdk-react";
import moment from "moment";
import { useIntl } from "react-intl";

export const Payment = ({ state, handleFormData, handleNext }: StepComponent) => {
  const { setIsCheckout, ga4Event } = useContext(FormDataContext);
  const { formatNumber } = useIntl()

  const [key, setKey] = useState<number>(0)

  console.log(state);

  //const { selectedProduct, product } = state || ({} as StateProps)

  const { id, name, categoryCode, amount }: Product = state || ({} as Product); //? state : selectedProduct || ({} as Product);

  useEffect(() => {
    setIsCheckout(true);
    initMercadoPago(process.env.MERCADO_PAGO_PUBLIC_KEY || "APP_USR-b2b1546a-b851-40ce-bae4-adce567a010c" /*"APP_USR-eecc58d2-4207-4503-b193-7a24f19e736b"*/)
  }, []);

  if (!handleNext || !handleFormData) return <></>;

  const initialization = {
    amount: amount || 0
  };
  
  const customization = {
    visual: {
      texts: {
        formTitle: "Asociación a nuestro Club",
        cardholderName: {
          label: "Nombre y Apellido como aparece en la tarjeta",
        },
        email: {
          label: "Email",
          placeholder: "Email",
        },
        cardholderIdentification: {
          label: "Nro de documento",
        },
        cardNumber: {
          label: "Nro de tarjeta",
        },
        expirationDate: {
          label: "Fecha de expiración",
        },
        securityCode: {
          label: "Código de seguridad",
        },
        formSubmit: `Pagar $${formatNumber(amount || 0, { currency: "ARS", format: "default" })}`,
      },
    },
    paymentMethods: {
      maxInstallments: 1,
    }
  };

  const onSubmit = async (formData: any): Promise<void> => {
    // callback llamado al hacer clic en el botón enviar datos
    const { token, transaction_amount, payer } = formData
    const contact = sessionStorage.getItem("c_id") || "";
    sessionStorage.removeItem("f_data");

    try {
      const subscription = await createSubscription({
        token,
        transactionAmount: transaction_amount,
        reason: `Club vinos. Asociación ${name}`,
        email: payer.email,
        backUrl: "https://www.unico.boutique",
        contact,
        product: id,
        startDate: moment().toISOString(),
        endDate: moment().add(10, 'years').toISOString()
      })
      const { Id, Amount, ExternalSubscriptionId } = subscription.data;
      
      await createOrder({ contact, paymentId: Id, totalAmount: Amount, totalQuantity: 1 })
          
      handleFormData({ "subscriptionId": ExternalSubscriptionId, "dni": payer.identification.number }, undefined)
      handleNext(categoryCode);      
    } catch (error) {
      console.log("Error al crear la suscripción")
      ga4Event({
        category: "Checkout",
        action: 'checkout_step_2 payment error',
        label: 'Pago'
      })
      setKey(prevKey => prevKey + 1)
    }
  };

  const onError = async (error: any) => {
    // callback llamado para todos los casos de error de Brick
    console.log("ERROR!!!!!")
    console.log(error);
  };


  return (
    <CardPayment
      key={key}
      initialization={initialization}
      customization={customization}
      onSubmit={onSubmit}
      onError={onError}
    />
  );
};
