import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import "./Breadcrumb.scss"

type BreadcrumbProps = {
  route: string;
  title: string;
};

export const Breadcrumb = ({ route, title }: BreadcrumbProps) => {
  const navigate = useNavigate();
  
  return (
    <div className="breadcrumb__container">
      {/* <ArrowBackIosIcon onClick={() => navigate(route)} /> */}
      <p>{title}</p>
    </div>
  );
};
