export const PRODUCTS = [
    {
        "Id": 19,
        "Name": "sel prueba",
        "Description": "",
        "CategoryCode": "UN",
        "EndDate": "2024-03-14T00:00:00+00:00",
        "ImageUrl": "Portada Seleccion de vinos Malbec Entre Copas y Corchos 02.jpg",
        "IsActive": false,
        "Pack": "[{\"name\":\"malb\",\"type\":\"Malbec\",\"quantity\":\"3\",\"description\":\"malbec 2013\",\"id\":\"7c9431b0-34e5-40f1-8ca0-f52b0eda33ec\"},{\"name\":\"Bon\",\"type\":\"Bonarda\",\"quantity\":\"3\",\"description\":\"bonarda 2020\",\"id\":\"cdca26e2-2a3a-4672-838a-ec3f28655c06\"}]",
        "CurrentPlanId": 6,
        "Plans": [
            {
                "Id": 6,
                "Name": "sel prueba",
                "Amount": 20000.0,
                "StartDate": "2024-02-27T00:00:00+00:00",
                "EndDate": "2024-02-29T00:00:00+00:00",
                "ExternalPlanId": "2c9380848e1d0a43018e2a53151e0831"
            }
        ]
    },
    {
        "Id": 20,
        "Name": "blanco 2",
        "Description": "",
        "CategoryCode": "BL",
        "EndDate": "2024-02-26T00:00:00+00:00",
        "ImageUrl": "Portada Seleccion de vinos Malbec Entre Copas y Corchos 02.jpg",
        "IsActive": true,
        "Pack": "[{\"name\":\"blanc\",\"type\":\"Chardonnay\",\"quantity\":\"6\",\"description\":\"6 chardo\",\"id\":\"79889469-11c2-4cc8-a6db-d5dd8f51e5a7\"}]",
        "CurrentPlanId": 7,
        "Plans": [
            {
                "Id": 7,
                "Name": "blanco 2",
                "Amount": 28000.0,
                "StartDate": "2024-02-21T00:00:00+00:00",
                "EndDate": "2024-02-29T00:00:00+00:00",
                "ExternalPlanId": "2c9380848e1d0a19018e2a531a24085e"
            }
        ]
    },
    {
        "Id": 21,
        "Name": "Premium",
        "Description": "",
        "CategoryCode": "PR",
        "EndDate": "2024-02-29T00:00:00+00:00",
        "ImageUrl": "Portada Seleccion de vinos Malbec Entre Copas y Corchos 02.jpg",
        "IsActive": true,
        "Pack": "[{\"name\":\"Pre\",\"type\":\"Merlot\",\"quantity\":\"3\",\"description\":\"Merlot\",\"id\":\"95403233-2563-4b70-acfe-01defede7f30\"},{\"name\":\"Pre\",\"type\":\"Cabernet Sauvignon\",\"quantity\":\"3\",\"description\":\"Cabe\",\"id\":\"e4b65fb6-44ab-43aa-b109-0dd91a4f1071\"}]",
        "CurrentPlanId": 8,
        "Plans": [
            {
                "Id": 8,
                "Name": "Premium",
                "Amount": 30000.0,
                "StartDate": "2024-02-21T00:00:00+00:00",
                "EndDate": "2024-02-29T00:00:00+00:00",
                "ExternalPlanId": "2c9380848e1d0a19018e2a531f31085f"
            }
        ]
    },
    {
        "Id": 22,
        "Name": "Deg",
        "Description": "",
        "CategoryCode": "DG",
        "EndDate": "2024-02-29T00:00:00+00:00",
        "ImageUrl": "Portada Seleccion de vinos Malbec Entre Copas y Corchos 02.jpg",
        "IsActive": true,
        "Pack": "[{\"name\":\"Degustacion prueba\",\"type\":\"Pinot Noir\",\"quantity\":\"3\",\"description\":\"Pinot\",\"id\":\"65f2743d-317c-4793-84a2-ad5b02ad9242\"},{\"name\":\"deg\",\"type\":\"Sauvignon Blanc\",\"quantity\":\"3\",\"description\":\"savig\",\"id\":\"f9f284bd-8360-4a15-8736-44f6d955366b\"}]",
        "CurrentPlanId": 9,
        "Plans": [
            {
                "Id": 9,
                "Name": "deg",
                "Amount": 30000.0,
                "StartDate": "2024-02-21T00:00:00+00:00",
                "EndDate": "2024-02-29T00:00:00+00:00",
                "ExternalPlanId": "2c9380848e1d0a19018e2a5324380860"
            }
        ]
    },
    {
        "Id": 31,
        "Name": "Seleccion Marzo",
        "Description": "",
        "CategoryCode": "UN",
        "EndDate": "2024-02-27T00:00:00+00:00",
        "ImageUrl": "fotos seleccion marzo.jpg",
        "IsActive": false,
        "Pack": "[{\"name\":\"Seleccion Unico Marzo\",\"type\":\"Malbec\",\"quantity\":\"3\",\"description\":\"Malbec Alighieri\",\"id\":\"a5914e01-d8a0-4591-b441-59a2f4e76fde\"},{\"name\":\"Selecci\\u00f3n \\u00danico Bonarda\",\"type\":\"Bonarda\",\"quantity\":\"3\",\"description\":\"Bonarda Bogart\",\"id\":\"99253dfb-91a2-4ec9-b05f-cfdfaaa0e515\"}]",
        "CurrentPlanId": 10,
        "Plans": [
            {
                "Id": 10,
                "Name": "Seleccion Marzo",
                "Amount": 28000.0,
                "StartDate": "2024-02-27T00:00:00+00:00",
                "EndDate": "2024-02-29T00:00:00+00:00",
                "ExternalPlanId": "2c9380848e1d0a2f018e2a53294d0811"
            }
        ]
    },
    {
        "Id": 32,
        "Name": "Seleccion Unico Marzo",
        "Description": "",
        "CategoryCode": "UN",
        "EndDate": "2024-02-28T00:00:00+00:00",
        "ImageUrl": "fotos seleccion marzo.jpg",
        "IsActive": false,
        "Pack": "[{\"name\":\"Malbec Unico Marzo\",\"type\":\"Malbec\",\"quantity\":\"3\",\"description\":\"Malbec Alighieri\",\"id\":\"881b8ac2-2eaa-430e-9ea8-35fed0ccc88c\"},{\"name\":\"Selecci\\u00f3n Unico Bonarda\",\"type\":\"Bonarda\",\"quantity\":\"3\",\"description\":\"Bonarda Bogart\",\"id\":\"4a5b550f-f889-4931-986d-0113d7f9eea0\"}]",
        "CurrentPlanId": 11,
        "Plans": [
            {
                "Id": 11,
                "Name": "Seleccion Unico Marzo",
                "Amount": 28000.0,
                "StartDate": "2024-02-27T00:00:00+00:00",
                "EndDate": "2024-02-29T00:00:00+00:00",
                "ExternalPlanId": "2c9380848e1d0a43018e2a532e4e0832"
            }
        ]
    },
    {
        "Id": 33,
        "Name": "Seleccion Unico Febrero",
        "Description": "",
        "CategoryCode": "UN",
        "EndDate": "2024-02-27T00:00:00+00:00",
        "ImageUrl": "1.jpg",
        "IsActive": true,
        "Pack": "[{\"name\":\"Bonarda 2020\",\"type\":\"Bonarda\",\"quantity\":\"3\",\"description\":\"Bonarda Bogart\",\"id\":\"7661d23d-1698-4547-9d5c-d0bfd48f10d7\"},{\"name\":\"Malbec 2020 \",\"type\":\"Malbec\",\"quantity\":\"3\",\"description\":\"Malbec Alighieri\",\"id\":\"0ebc0909-eed4-4546-8169-9f8ceb8740fc\"}]",
        "CurrentPlanId": 12,
        "Plans": [
            {
                "Id": 12,
                "Name": "Seleccion Unico Febrero",
                "Amount": 28000.0,
                "StartDate": "2024-02-28T00:00:00+00:00",
                "EndDate": "2024-02-29T00:00:00+00:00",
                "ExternalPlanId": "2c9380848e1d0a2f018e2a53334a0812"
            }
        ]
    },
    {
        "Id": 40,
        "Name": "egferger",
        "Description": "",
        "CategoryCode": "DG",
        "EndDate": "2024-03-03T00:00:00+00:00",
        "ImageUrl": "WhatsApp Image 2024-02-11 at 21.46.02 (3).jpeg",
        "IsActive": true,
        "Pack": "[{\"name\":\"wertwert\",\"type\":\"Merlot\",\"quantity\":\"3\",\"description\":\"freag\",\"id\":\"4d222ca9-9f00-42d7-b1bc-94a19e070503\"},{\"name\":\"234rfes\",\"type\":\"Malbec\",\"quantity\":\"23\",\"description\":\"vsdfvsdfv\",\"id\":\"49dedbc2-ce7f-461a-9e47-9fc6b817d0a6\"}]",
        "CurrentPlanId": 14,
        "Plans": [
            {
                "Id": 14,
                "Name": "egferger",
                "Amount": 234234.0,
                "StartDate": "2024-03-04T00:00:00+00:00",
                "EndDate": "2024-03-28T00:00:00+00:00",
                "ExternalPlanId": "2c9380848e1d0a19018e2a5338430861"
            },
            {
                "Id": 15,
                "Name": "egferger",
                "Amount": 234234.0,
                "StartDate": "2024-03-29T00:00:00+00:00",
                "EndDate": "2024-04-11T00:00:00+00:00"
            },
            {
                "Id": 16,
                "Name": "egferger",
                "Amount": 3434.0,
                "StartDate": "2024-04-12T00:00:00+00:00",
                "EndDate": "2024-04-30T00:00:00+00:00"
            }
        ]
    },
    {
        "Id": 50,
        "Name": "test1",
        "Description": "",
        "CategoryCode": "PR",
        "EndDate": "2024-02-25T00:00:00+00:00",
        "ImageUrl": "WhatsApp Image 2024-02-11 at 21.46.02 (3).jpeg",
        "IsActive": true,
        "CurrentPlanId": 18,
        "Pack": "[{\"name\":\"wearwer\",\"type\":\"Chardonnay\",\"quantity\":\"2\",\"description\":\"asdfadsf\",\"id\":\"a02b5039-6b75-483a-9cbb-26b61823cd7b\"}]",
        "Plans": [
            {
                "Id": 17,
                "Name": "test1",
                "Amount": 23440.0,
                "StartDate": "2024-03-04T00:00:00+00:00",
                "EndDate": "2024-03-09T00:00:00+00:00",
                "ExternalPlanId": "2c9380848e1d0a43018e2a533d3b0833"
            },
            {
                "Id": 18,
                "Name": "test1",
                "Amount": 33000.0,
                "StartDate": "2024-03-10T00:00:00+00:00",
                "EndDate": "2024-04-17T00:00:00+00:00",
                "ExternalPlanId": "2c9380848e1d0a19018e2aeafb37096c"
            },
            {
                "Id": 20,
                "Name": "test1",
                "Amount": 63466.0,
                "StartDate": "2024-04-17T00:00:00+00:00",
                "EndDate": "2024-04-30T00:00:00+00:00"
            }
        ]
    }
]