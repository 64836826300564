import { useContext, useState } from "react";
import { useIntl } from "react-intl";
import Messages from "../../lang/Messages";
import { TextField } from "@mui/material";
import "./Contact.scss";
import { createContact } from "../../services/endPoints";
import { ButtonLoader } from "../Commons/ButtonLoader";
import { FormDataContext } from "../../App";

export const Contact = () => {
  const { formatMessage } = useIntl();
  const { ga4Event } = useContext(FormDataContext);
  
  const [contactData, setContactData] = useState<Record<string, string>>(
    {} as Record<string, string>
  );
  const [errors, setErrors] = useState<Record<string, string>>(
    {} as Record<string, string>
  );

  const [isSaving, setIsSaving] = useState<Boolean>(false);

  const handleError = (keyError: string, newValue: any, message: string) => {
    let newError = "";
    if (
      !newValue ||
      (!!newValue && typeof newValue === "string" && !newValue.trim())
    )
      newError = message;

    setErrors({ ...errors, [keyError]: newError });
  };

  const saveContact = () => {
    setIsSaving(true)
    const action = 'contact_us'
    const label = 'Consulta vía mail'
    ga4Event({
      category: "Information",
      action,
      label
    })
    window.mt("send", "pageview", { page_title: 'Contact', label, category: 'Information', action });
    createContact({ ...contactData, stage: 1 }).then((response) => {
      const userId = response.data.Id;      
      setIsSaving(false)
    })
  }

  const { name, lastName, email, phoneNumber, notes } = contactData;

  return (
    <div className="contact__container container">
      <div className="contact__img">
        <h3>{formatMessage(Messages.contact_title)}</h3>
      </div>

      <div className="contact__form">
        <TextField
          id="name-input"
          label={formatMessage(Messages.contact_name_label)}
          value={name}
          variant="outlined"
          fullWidth
          placeholder={formatMessage(Messages.contact_name)}
          error={!!errors.nameError}
          helperText={formatMessage(Messages.contact_name_label)}
          onChange={(event) => {
            const { value } = event.target;
            setContactData({ ...contactData, name: value || "" });
            handleError(
              "nameError",
              value,
              formatMessage(Messages.contact_name_error)
            );
          }}
        />
        <TextField
          id="lastName-input"
          label={formatMessage(Messages.contact_lastName_label)}
          value={lastName}
          variant="outlined"
          fullWidth
          placeholder={formatMessage(Messages.contact_lastName)}
          helperText={formatMessage(Messages.contact_lastName_label)}
          onChange={(event) => {
            const { value } = event.target;
            setContactData({ ...contactData, lastName: value || "" });
          }}
        />
        <TextField
          id="email-input"
          type="email"
          label={formatMessage(Messages.contact_email_label)}
          value={email}
          variant="outlined"
          fullWidth
          placeholder={formatMessage(Messages.contact_email)}
          error={!!errors.emailError}
          helperText={formatMessage(Messages.contact_email_label)}
          onChange={(event) => {
            const { value } = event.target;
            setContactData({ ...contactData, email: value || "" });
            handleError(
              "emailError",
              value,
              formatMessage(Messages.contact_email_error)
            );
          }}
        />
        <TextField
          id="phone-input"
          type="number"
          label={formatMessage(Messages.contact_phone_label)}
          value={phoneNumber}
          variant="outlined"
          fullWidth
          placeholder={formatMessage(Messages.contact_phone)}
          helperText={formatMessage(Messages.contact_phone_label)}
          onChange={(event) => {
            const { value } = event.target;
            setContactData({ ...contactData, phoneNumber: value || "" });
          }}
        />
        <div className="contact__notes">
            <TextField
            id="notes-input"
            multiline
            rows={5}
            value={notes}
            fullWidth
            variant="outlined"
            placeholder={formatMessage(Messages.contact_notes)}
            onChange={(event) => {
                const { value } = event.target;
                setContactData({ ...contactData, notes: value || "" });
            }}
            />
        </div>
        <ButtonLoader isValidForm={true} save={saveContact} isSaving={isSaving} buttonText={formatMessage(Messages.contact_button)} />
      </div>
    </div>
  );
};
