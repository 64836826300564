import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { LocalityResponse } from "../Types";
import { useIntl } from "react-intl";
import Messages from "../../lang/Messages";

type LocalityProps = {
  items: LocalityResponse[];
};

export const Localities = (props: LocalityProps) => {
  const { items } = props;
  const { formatMessage } = useIntl()

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 350 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{formatMessage(Messages.locality_item_name)}</TableCell>
            <TableCell align="right">{formatMessage(Messages.locality_item_description)}</TableCell>
            <TableCell align="right">{formatMessage(Messages.locality_item_cost)}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row) => (
            <TableRow key={row.Name}>
              <TableCell align="left">{row.Name}</TableCell>
              <TableCell align="left">{row.Description}</TableCell>
              <TableCell align="center">{row.Cost}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
