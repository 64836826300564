import { useIntl } from "react-intl";
import cn from "classnames";
import { CircularProgress } from "@mui/material";
import "./ButtonLoader.scss"

type ButtonProps = {
    isValidForm: Boolean; 
    save: () => void;
    isSaving: Boolean;
    buttonText: string;
}

export const ButtonLoader = ({ isValidForm, save, isSaving, buttonText }: ButtonProps) => {
  const { formatMessage } = useIntl();

  return (
    <div className="button__container">
      <button
        disabled={!isValidForm || !!isSaving}
        className={cn({
          disabled: !!isSaving,
        })} onClick={save}>{buttonText}</button>
      {isSaving && <CircularProgress size={24} />}
    </div>
  );
};
