import { useIntl } from "react-intl";
import { Breadcrumb } from "../Commons/Breadcrumb";
import Messages from "../../lang/Messages";
import "./SelectionDetail.scss";
import { Divider } from "@mui/material";
import { Shipment } from "../Commons/Shipment";
import { useState, useContext, useEffect } from "react";
import { FormDataContext } from "../../App";
import { Pack, Product } from "../Types";
import { useLocation, useNavigate } from "react-router-dom";

type SelectionDetailProps = {
  product?: Product;
  isResume?: Boolean;
}

export const SelectionDetail = ({ product, isResume }: SelectionDetailProps) => {
  const { formatMessage, formatNumber } = useIntl();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { setIsCheckout, isMobile, ga4Event } = useContext(FormDataContext);

  useEffect(() => {
    setIsCheckout(false);
  }, []);

  const [shipmentAmount, setShipmentAmount] = useState<number>();

  const { imageUrl, name, listPrice, memberPrice, description, categoryCode, pack } = state || product;

  const handleCheckout = (categoryCode: string) => {
    ga4Event({
      category: "Checkout",
      action: `checkout_step_1_${formatMessage(Messages[`product_${categoryCode.toLowerCase()}` as keyof typeof Messages])}`,
      label: "Proceso checkout",
    })
    window.mt("send", "pageview", { page_title: 'Detalle Selección', label: 'Proceso checkout', category: 'Checkout' });
    navigate(`/${Messages.route_checkout.id}`, { state } )
  }
  
  const MobileDetail = () => {
    return (
      <>
        <Breadcrumb title={formatMessage(Messages.selection_detail_back_title)} route={Messages.route_products.id} />
        <div className="detail__container">
          <h3 className="detail__name">{name}</h3>
          <img src={imageUrl} alt={imageUrl} />
          <div className="detail__info">
            {listPrice && (
              <p className="small__text">
                {formatMessage(Messages.selection_detail_list_price)}{" "}
                {formatNumber(listPrice, {
                  currency: "ARS",
                  format: "default",
                })}
              </p>
            )}
            {memberPrice && (
              <p className="sales__price">
                {formatMessage(Messages.selection_detail_member_price)}{" "}
                {formatNumber(memberPrice, {
                  currency: "ARS",
                  format: "default",
                })}
              </p>
            )}
            {memberPrice && (
              <p className="total__price">
                {formatMessage(Messages.selection_detail_total_price)}{" "}
                {formatNumber(
                  shipmentAmount ? memberPrice + shipmentAmount : memberPrice,
                  {
                    currency: "ARS",
                    format: "default",
                  }
                )}
              </p>
            )}
            <button className="detail__button" onClick={() => handleCheckout(categoryCode)}>
              {formatMessage(Messages.selection_detail_button)}
            </button>
            <div className="detail__description">
              <h3>{formatMessage(Messages.selection_detail_decription)}</h3>
              <p className="small__text">{description.replace(/<br\s*\/?>/g, '\n')}</p>
            </div>
            <Divider />
          </div>
        </div>
      </>
    );
  };

  const DesktopDetail = () => {
    return (
      <div className="detail__container">
        <div className="detail__img">
          <img src={imageUrl} alt={imageUrl} />
          <div className="detail__description">
            <h3>{formatMessage(Messages.selection_detail_decription)}</h3>
            <p className="small__text">{description}</p>
          </div>
        </div>
        <div className="detail__info">
        <Breadcrumb title={formatMessage(Messages.selection_detail_back_title)} route={Messages.route_products.id} />
          <h3 className="detail__name">{name}</h3>          
          {memberPrice && (
            <p className="sales__price">
              {formatMessage(Messages.selection_detail_member_price)}{" "}
              {formatNumber(memberPrice, {
                currency: "ARS",
                format: "default",
              })}
            </p>
          )}
            {listPrice && (
            <p className="small__text">
              {formatMessage(Messages.selection_detail_list_price)}{" "}
              {formatNumber(listPrice, {
                currency: "ARS",
                format: "default",
              })}
            </p>
          )}
          <p className="detail__pack">{formatMessage(Messages.selection_detail_pack)}
            {pack.map(({ type, quantity }: Pack) => (` ${quantity} ${type}`)).join(',')}
          </p>
          {memberPrice && (
            <p className="total__price">
              {formatMessage(Messages.selection_detail_total_price)}{" "}
              {formatNumber(
                shipmentAmount ? memberPrice + shipmentAmount : memberPrice,
                {
                  currency: "ARS",
                  format: "default",
                }
              )}
            </p>
          )}
          <button className="detail__button" onClick={() => handleCheckout(categoryCode)}>
            {formatMessage(Messages.selection_detail_button)}
          </button>
        </div>
      </div>
    );
  };

  const ResumeDetail = () => {
    return (
      // <>
      //   <div className="resume__container">
      //     <h3 className="resume__name">{name}</h3>
      //     <img src={imageUrl} alt={imageUrl} />
      //     <div className="resume__info">
      //       {listPrice && (
      //         <p className="small__text">
      //           {formatMessage(Messages.selection_detail_list_price)}{" "}
      //           {formatNumber(listPrice, {
      //             currency: "ARS",
      //             format: "default",
      //           })}
      //         </p>
      //       )}
      //       {memberPrice && (
      //         <p className="sales__price">
      //           {formatMessage(Messages.selection_detail_member_price)}{" "}
      //           {formatNumber(memberPrice, {
      //             currency: "ARS",
      //             format: "default",
      //           })}
      //         </p>
      //       )}
      //       {memberPrice && (
      //         <p className="total__price">
      //           {formatMessage(Messages.selection_detail_total_price)}{" "}
      //           {formatNumber(
      //             shipmentAmount ? memberPrice + shipmentAmount : memberPrice,
      //             {
      //               currency: "ARS",
      //               format: "default",
      //             }
      //           )}
      //         </p>
      //       )}
      //       <div className="resume__description">
      //         <p className="detail__pack">{formatMessage(Messages.selection_detail_pack)}</p>
      //         {pack.map(({ type, quantity }: Pack) => (<p className="resume__pack">{`${quantity} ${type}`}</p>))}
      //       </div>
      //     </div>
      //   </div>
      // </>
          <div className="checkout__resume">
            <img className="checkout__img" src={imageUrl} alt={imageUrl} />
            <div className="detail__product">
              <h3>{name}</h3>
              <div className="detail__pack">
                {pack.map(({ type, quantity }: Pack) => (
                  <p>{`${quantity} ${type}`}</p>
                ))}
              </div>
              {listPrice && (
                <p className="detail__price">
                  {formatMessage(Messages.selection_detail_list_price)}{" "}
                  {formatNumber(listPrice, {
                    currency: "ARS",
                    format: "default",
                  })}
                </p>
              )}
              {memberPrice && (
                <p className="detail__price">
                  {formatMessage(Messages.selection_detail_member_price)}{" "}
                  {formatNumber(memberPrice, {
                    currency: "ARS",
                    format: "default",
                  })}
                </p>
              )}
            </div>
          </div>
    );
  }

  return (isResume ? <ResumeDetail /> : <div className="seldetail__container container">
    {isMobile ? <MobileDetail /> : <DesktopDetail />}
  </div>);
};
