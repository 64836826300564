import { useContext, useEffect, useState } from "react";
import "./ProductAdmin.scss";
import { deleteProduct } from "../../services/endPoints";
import { ProductsTable } from "./ProductsTable";
import { FormDataContext } from "../../App";
import { useNavigate } from "react-router-dom";
import Messages from "../../lang/Messages";

export const ProductAdmin = () => {
  const [isSaving, setIsSaving] = useState<Boolean>(false);
  const { accessToken } = useContext(FormDataContext);
  const navigate = useNavigate()

  useEffect(() => {
    if(!accessToken)
      navigate(`/${Messages.route_login.id}`)
  }, [])

  const handleDeleteProducts = (ids: readonly number[]) => {
    setIsSaving(true)
    const deletedProducts = ids.map(async (id) => await deleteProduct(id))
    Promise.all(deletedProducts)
    setIsSaving(false)
  };

  return (
    <div className="productadmin">
      <ProductsTable handleDeleteProducts={handleDeleteProducts}/>
    </div>
  );
};
