import { useIntl } from "react-intl";
import Messages from "../../lang/Messages";
import { Pack, Product } from "../Types";
import { Autocomplete, Avatar, Chip, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import "./PackForm.scss";

type PackAdminProps = {
  handleAddPack: (newPack: Pack) => void;
};

const WINE_TYPES = [
  "Cabernet Sauvignon",
  "Malbec",
  "Chardonnay",
  "Merlot",
  "Pinot Noir",
  "Sauvignon Blanc",
  "Syrah/Shiraz",
  "Riesling",
  "Cabernet Franc",
  "Bonarda",
  "Petit Verdot",
  "Blend",
  "Malbec - Syrah",
  "Torrontés"
];

export const PackForm = ({ handleAddPack }: PackAdminProps) => {
  const { formatMessage } = useIntl();

  const [newPack, setNewPack] = useState<Pack>({} as Pack);
  const [errors, setErrors] = useState<Record<string, string>>(
    {} as Record<string, string>
  );

  const handleError = (keyError: string, newValue: any, message: string) => {
    let newError = "";
    if (!newValue || (!!newValue && typeof newValue === "string" && !newValue.trim()))
      newError = message;

    setErrors({ ...errors, [keyError]: newError });
  };

  const handlePack = (key: string, value: string | number | null) => {
    setNewPack({ ...newPack, [key]: value });
  };

  const formIsValid = () => {
    const errorsAux =
      Object.keys(errors).filter(
        (key) => errors[key].trim().length > 0
      ) || [];

    return errorsAux.length === 0;
  };

  return (
    <div className="packadmin__wrapper">
      <h3>{formatMessage(Messages.pack_title)}</h3>
      <TextField
        fullWidth
        label={formatMessage(Messages.pack_name)}
        variant="outlined"
        error={!!errors.nameError}
        value={newPack.name || ''}
        onChange={(event) => {
          const { value } = event.target;
          handlePack("name", value);
          handleError(
            "nameError",
            value,
            formatMessage(Messages.pack_name_error)
          );
        }}
        onBlur={(event) => {
          const { value } = event.target;
          handleError(
            "nameError",
            value,
            formatMessage(Messages.pack_name_error)
          );
        }}
      />
      <div className="packadmin__types">
        <Autocomplete
          fullWidth
          id="wine-types"
          options={WINE_TYPES}
          autoHighlight
          getOptionLabel={(option) => option}
          value={newPack.type || ''}
          onChange={(_, newValue) => {
            handlePack("type", newValue);
            handleError(
              "typeError",
              newValue,
              formatMessage(Messages.pack_type_error)
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={formatMessage(Messages.pack_type)}
              error={!!errors.typeError}
              onBlur={(event) => {
                const { value } = event.target;
                handleError(
                  "typeError",
                  value,
                  formatMessage(Messages.pack_type_error)
                );
              }}
            />
          )}
        />
        <TextField
          id="quantity-input"
          label={formatMessage(Messages.pack_quantity)}
          variant="outlined"
          value={newPack.quantity || ''}
          error={!!errors.quantityError}
          onChange={(event) => {
            const { value } = event.target;
            handlePack("quantity", value);
            handleError(
              "quantityError",
              value,
              formatMessage(Messages.pack_quantity_error)
            );
          }}
          onBlur={(event) => {
            const { value } = event.target;
            handleError(
              "quantityError",
              value,
              formatMessage(Messages.pack_quantity_error)
            );
          }}
        />
      </div>
      {/* <TextField
        fullWidth
        // multiline
        // rows={4}
        id="description-input"
        label={formatMessage(Messages.pack_description)}
        variant="outlined"
        value={newPack.description || ''}
        error={!!errors.descriptionError}
        onChange={(event) => {
          const { value } = event.target;
          handlePack("description", value);
          handleError(
            "descriptionError",
            value,
            formatMessage(Messages.pack_description_error)
          );
        }}
        onBlur={(event) => {
          const { value } = event.target;
          handleError(
            "descriptionError",
            value,
            formatMessage(Messages.pack_description_error)
          );
        }}
      /> */}
      <button className="packform__add" disabled={!formIsValid()} onClick={() => {
          handleAddPack(newPack)
          setNewPack({} as Pack)
        }}>
        {formatMessage(Messages.pack_add_button)}
      </button>
    </div>
  );
};
