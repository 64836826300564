import { useIntl } from "react-intl";
import Messages from "../../lang/Messages";
import "./Benefits.scss";
import { useNavigate } from "react-router-dom";

export const Benefits = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const showMoreInfo = () => {
   // window.mt("send", "pageview", { page_title: "moreInfo" });
    navigate(`/${Messages.route_club.id}`)
  };

  return (
    <div className="benefits__wrapper">
      <h3>
        {formatMessage(Messages.benefits_title1)}
        <span>{formatMessage(Messages.benefits_title2)}</span>
        {formatMessage(Messages.benefits_title3)}
      </h3>
      <div className="benefits__container">
        <img src='friendsdrinkingwine.jpg' alt='friendsdrinkingwine' />
        <div className="benefits">
          <h3 className="benefits_subtitle">
            {formatMessage(Messages.benefits_subtitle)}
          </h3>
          <div className="benefits__text">
            <p>{formatMessage(Messages.benefits_detail1)}</p>
            <p>{formatMessage(Messages.benefits_detail2)}</p>
            <p>{formatMessage(Messages.benefits_detail3)}</p>
            <p>{formatMessage(Messages.benefits_detail4)}</p>
          </div>
          <button onClick={showMoreInfo}>
            {formatMessage(Messages.benefits_info)}
          </button>
        </div>
      </div>
    </div>
  );
};
