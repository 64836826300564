import { useContext, useEffect, useState } from "react"
import { useIntl } from "react-intl";
import { Plan, Product } from "../Types";
import Messages from "../../lang/Messages";
import "./ProductCard.scss";
import { useNavigate } from "react-router-dom";
import { FormDataContext } from "../../App";
import moment from "moment";

type ProductCardProps = {
  item: Product;
  withoutText?: boolean;
};

export const ProductCard = ({ item, withoutText }: ProductCardProps) => {
  const { formatMessage, formatNumber } = useIntl();
  const navigate = useNavigate();
  const { setCurrentProduct, ga4Event } = useContext(FormDataContext);

  const { imageUrl, name, description, plans } = item;

  const [currentPrice, setCurrentPrice] = useState<Plan>({} as Plan);

  const handleAssociation = (item: Product) => {
    ga4Event({
      category: "Association",
      action: `start_${formatMessage(Messages[`product_${item.categoryCode.toLowerCase()}` as keyof typeof Messages])}_association`,
      label: "Comienza proceso asociación",
    })
    window.mt("send", "pageview", { page_title: 'Selecciones', label: 'Comienza proceso asociación', category: 'Association' });
    navigate(`/${Messages.route_selection_detail.id}`, { state: item })
  }

  const getCurrentPrice = () => {  
    setCurrentPrice(plans.find(({ startDate, endDate }) => moment().isAfter(moment(startDate)) && moment().isBefore(moment(endDate))) || {} as Plan)
  }

  useEffect(() => {
      setCurrentProduct(item)
      getCurrentPrice()
  }, [])

  return (
    <div className="product__container">
        <img src={`/${imageUrl}`} alt={imageUrl} />
      {!withoutText && <div className="product__wrapper">
        <div className="product__info">
            <h3 className="product__selection">{name}</h3>
            <p className='small__text'>{description}</p>
            {currentPrice.amount && (<p className="sales__price">{formatMessage(Messages.product_member_price)} {formatNumber(parseInt(currentPrice.amount), { currency: "ARS", format: "default" })}</p>)}
            <button onClick={() => handleAssociation(item)}>{formatMessage(Messages.selections_button)}</button>
        </div>
      </div>}
    </div>
  );
};
