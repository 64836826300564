import { useContext } from "react";
import { Navbar } from "./Navbar";
import "./Header.scss";
import { FormDataContext } from "../../App";

export const Header = () => {
  const { isMobile } = useContext(FormDataContext);

  return (
    <div className="container__header header__fixed">
      <div className="header__icons">
        {isMobile && <Navbar />}
        <img src='logo.svg' alt='logo' />
        {!isMobile && (
          <div className="header__desktop">
            <Navbar />
          </div>
        )}
      </div>
    </div>
  );
};
