import axios from "axios";
import moment from "moment";
import api from "./api";
import { v4 as uuidv4 } from 'uuid';

const SESSION_API_KEY = "_session_ucv_"
const BASE_URL = process.env.REACT_APP_BASE_APP_URL

const createApiInstance = (baseURL: string | undefined) => {
  return axios.create({ baseURL })
}
const getAccessTokenAndSaveSession = async () => {
  const response = await api.run({
    action: "post",
    endpoint: "oauth/v2/token",
    data: {
      client_id: process.env.REACT_APP_CLIENT_ID_API,
      client_secret: process.env.REACT_APP_CLIENT_SECRET_API,
      grant_type: "client_credentials",
    },
  });

  const credentials = response.data

  const expiresIn = moment().utc().add(credentials.expires_in, 'seconds')
  const newSession = { credentials, expiresAt: expiresIn }
  const currentUserId = sessionStorage.getItem("userId")
  let userId = currentUserId;
  if(!currentUserId) {
    userId = uuidv4();
    sessionStorage.setItem(SESSION_API_KEY, userId)
  }
  apiNodeInstance.post("session", { sessionId: `apiSession:${userId}`, session: newSession })

  return newSession
};

const apiMauticInstance = createApiInstance(process.env.REACT_APP_BASE_API_URL || "");
const apiNodeInstance = createApiInstance(process.env.REACT_APP_BASE_APP_URL);

const apiInstanceExternal = axios.create({
  //baseURL: process.env.REACT_EXTERNAL_BASE_URL,
 // baseURL: "https://apis.datos.gob.ar/georef/api",
});

const URL_OAUTH = "oauth/v2/token";

apiMauticInstance.interceptors.request.use(
  async (config) => {
    let sessionKey = sessionStorage.getItem(SESSION_API_KEY);
    console.log(config.url, sessionKey)
 
    if(!sessionKey) { 
        sessionKey = uuidv4();
        sessionStorage.setItem(SESSION_API_KEY, sessionKey as string)
    }

    const session = sessionStorage.getItem(sessionKey as string)

    if((!session) && config.url !== URL_OAUTH) {
        let { credentials } = await getAccessTokenAndSaveSession();
        config.headers["Authorization"] = "Bearer " + credentials.access_token
        return config;
    }

    if(!session) return config;

    const sessionJSON = JSON.parse(session || '');
    const { credentials, expiresAt } = sessionJSON

    if(moment().isAfter(moment(expiresAt)) && config.url !== URL_OAUTH) {
      const token = await getAccessTokenAndSaveSession()
      config.headers["Authorization"] = "Bearer " + token.credentials.access_token;

      return config;
    }

    config.headers["Authorization"] = "Bearer " + credentials.access_token;
    return config
  },
  (error) => {
    Promise.reject(error);
  }
);

//apiMauticInstance.interceptors.response.use(
  //(response: AxiosResponse) => {
  // response => {
  //   response.headers['Access-Control-Allow-Origin'] = '*'
  //   return response;
  // },
  // function (error) {
  //   console.log(error);
  //   if (!error.response) throw "ERR_CONNECTION_REFUSED";
  //   if (error.response.status == 401 && !!loggedIn) {
  //     logOut();
  //     router.push("/login");
  //   } else if (error.response.status == 401) {
  //     removeAuthData();
  //     router.push("/login");
  //   }
  //   //console.log(error.response.data);
  //   throw error.response.data;
  //   //return { status: error.response.status, data: error.response.data };
  // }
//);

export { apiMauticInstance, apiNodeInstance, apiInstanceExternal };
