import { useLocation } from "react-router-dom";
import "./Checkout.scss";
import { useEffect, useContext, useState } from "react";
import { FormDataContext } from "../../App";
import { CheckoutStepper } from "./CheckoutStepper";
import { StepperProps } from "../Types";
import { getSteppers } from "./Metadata";

export const Checkout = () => {
  const [steps, setSteps] = useState<StepperProps[]>([]);
  const { setIsCheckout } = useContext(FormDataContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const externalAmount = parseInt(queryParams.get('amount') || '0')
  const externalCategory = queryParams.get('category') || ''
  const externalId = queryParams.get('externalId') || ''

  useEffect(() => {
    setIsCheckout(true);
    setSteps(getSteppers());
  }, []);

  return (
    <div className="checkout__container container">
      <div className="checkout__stepper">
        {steps.length && <CheckoutStepper externalAmount={externalAmount} externalCategory={externalCategory} externalId={externalId} steps={steps} />}
      </div>
    </div>
  );
};
