import { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import Messages from "../../lang/Messages";
import "./Login.scss";
import { FormBuilder } from "../Commons/FormBuilder";
import { getLoginFields } from "./Metadata";
import { login, saveSessionRedis, getSessionRedis, createContact, getContactBy, delSessionRedis } from "../../services/endPoints";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { v4 } from "uuid"
import { FormDataContext } from "../../App";

export const Login = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const { accessToken, setAccessToken } = useContext(FormDataContext);

  const [formData, setFormData] = useState<Record<string, string>>({} as Record<string, string>);
  const [errors, setErrors] = useState<Record<string, string>>({} as Record<string, string>);
  const [profile, setProfile] = useState<Record<string, string>>();

  const currentPath = location.pathname

  // useEffect(() => {
  //   const userId =  sessionStorage.getItem("userId")
  //   console.log("ACCESSTOKEN", accessToken)
  //   if (!accessToken) {
  //    // if(userId) navigate(Messages.route_login.id); //TODO. En este caso ya no tiene los datos del usuario logueado. Expiró la sesión

  //     if(userId) handleSessionFromRedis(userId || "")
  //     return;
  //   }  
  //   setProfile(decodeJwt(accessToken))
  // }, [accessToken]);

  useEffect(() => {
    if(profile) {
      getContactBy(profile.email).then((response) => {
        const { total } = response.data
        if(total === "0")
          createContact({ ...profile, stage: 1 })
      })
      navigate(`/${Messages.route_home.id}`)
    }
  } ,[profile])

  const decodeJwt = (token: string) => {
    const parts = token.split(".");
    if (parts.length !== 3) {
      throw new Error("Invalid token format");
    }
    const payload = JSON.parse(atob(parts[1]));
    const { email, given_name, family_name, picture } = payload
    return { email, firstname: given_name, lastname: family_name, picture };
  }

  const handleSessionFromRedis = (userId: string) => {
    console.log("USER", userId)
    getSessionRedis(`userSession:${userId}`).then((response) => {
      const accessToken = response.data;
      if(!accessToken) currentPath.includes("login") ? navigate("") : navigate(`/${Messages.route_login.id}`)
      setProfile(decodeJwt(accessToken))
    })
    .catch((error) => {
      const messageError = error.response.data
      console.log(messageError)
      if(messageError === 'USER_NOT_FOUND') handleLogout()
    });
  };

  const handleLogin = async () => {
    const response = await login(formData);
    const { data } = response
    console.log("LOGINDATA", data)
    const userId = sessionStorage.getItem("userId") || v4();
    await saveSessionRedis({ sessionId: `userSession:${userId}`, session: data })
    sessionStorage.setItem("userId", userId)
    setAccessToken(data.access_token || "")
    console.log("ACCESSTOKEN", data.access_token)
    if (!data.access_token) {
     // if(userId) navigate(Messages.route_login.id); //TODO. En este caso ya no tiene los datos del usuario logueado. Expiró la sesión

      if(userId) handleSessionFromRedis(userId || "")
        return;
    }  
    navigate(`/${Messages.route_prod_admin.id}`);
    // const userId =  sessionStorage.getItem("userId")
    // console.log("ACCESSTOKEN", accessToken)
    // if (!accessToken) {
    //  // if(userId) navigate(Messages.route_login.id); //TODO. En este caso ya no tiene los datos del usuario logueado. Expiró la sesión

    //   if(userId) handleSessionFromRedis(userId || "")
    //   return;
    // }  
    // setProfile(decodeJwt(accessToken))
  };
  
  const handleLogout = () => {
    const userId =  sessionStorage.getItem("userId")
    console.log(userId)
    delSessionRedis(`userSession:${userId}`).then(() => {
      setProfile({})
      sessionStorage.removeItem("userId")
      navigate(`/${Messages.route_home.id}`)
    })
  }

  const handleError = (keyError: string, newValue: any, message: string) => {
    let newError = "";
    if (
      !newValue ||
      (!!newValue && typeof newValue === "string" && !newValue.trim())
    )
      newError = message;

    setErrors({ ...errors, [keyError]: newError });
  };

  const handleFormData = (key: string | Record<string, string>, value: string | undefined) => {
    if(typeof key === "string")
      setFormData({ ...formData, [key]: value || "" });
  };

  const responseMessage = (response: CredentialResponse) => {
    const { credential } = response
    const userId = sessionStorage.getItem("userId") || v4();
    saveSessionRedis({ sessionId: `userSession:${userId}`, session: credential }).then(() => {
      sessionStorage.setItem("userId", userId)
      setAccessToken(credential || "")
    });
  };

  const errorMessage = () => {
    console.log("ERROR");
  };

  return (
    <div className="login__container">
      <FormBuilder formFields={getLoginFields()} formData={formData} handleFormData={handleFormData} handleError={handleError} errors={errors} />
      <button onClick={handleLogin}>
        {formatMessage(Messages.login_button)}
      </button>
      <a onClick={() => navigate(`/${Messages.route_register.id}`)}>
        Registrarme
      </a>
      {/* <GoogleLogin onSuccess={responseMessage} onError={errorMessage} /> */}
    </div>
  );
};
