import { useIntl } from "react-intl";
import Messages from "../../lang/Messages";
import { Product } from "../Types";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import { useEffect, useState } from "react";
import { Divider, Skeleton } from "@mui/material";
import "./Wines.scss";
import { getFilteredProducts } from "../Utils/getAPIData";

export const WinesMobile = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const [products, setProducts] = useState<Product[]>([]);
  const [isWaiting, setIsWaiting] = useState<Boolean>(true);
  const [lastIndex, setLastIndex] = useState<number>(0);
  const [currentProduct, setCurrentProduct] = useState<Product>({} as Product);

  useEffect(() => {
      const fetchProducts = async () => {
      try {
        const newProducts = await getFilteredProducts()

      setProducts(newProducts.sort((prod1, prod2) => prod1.categoryCode < prod2.categoryCode ? 1 : -1));
      setCurrentProduct(newProducts[lastIndex])
        setIsWaiting(false)
      }
      catch {
        setIsWaiting(false)
      };
    }

    fetchProducts()
  }, []);

  const changeProduct = (event: React.MouseEvent<HTMLDivElement>) => {
    const currentProductIndex = products.findIndex(({ categoryCode }) => categoryCode === event.currentTarget.id)

    if (currentProductIndex < 0) return;

    console.log("INDEX", currentProductIndex)

    setCurrentProduct(products[currentProductIndex])
    setLastIndex(currentProductIndex)
  };

  const ellipsis = (message: string) => {
    return message.length > 6 ? `${message.substring(0, 6)}...` : message;
  };

  const associate = () => {
    window.mt("send", "pageview", { page_title: "associate" });
    navigate(`/${Messages.route_selection_detail.id}`, { state: currentProduct })
  };

  return (
    <div className="wines__container">
      {isWaiting ? <Skeleton variant="rectangular" width={window.innerWidth * 0.8} height={460} /> : 
      <>
        <h3>{formatMessage(Messages.wines_title)}</h3>
        <div className="wines__selection">
          <Divider />
          <div className="wines__selector">
            {products.map(({ categoryCode }, index) => (
              <div className={cn("wine__selector", { current__selector: index === lastIndex })}
                id={categoryCode}
                onClick={(event) => changeProduct(event)}>
                {ellipsis(
                  formatMessage(
                    Messages[`product_${categoryCode.toLowerCase()}` as keyof typeof Messages]
                  )
                )}
              </div>
            ))}
          </div>
          <Divider />
          <div className="image__container">
            {products.map(({ imageUrl }, index) => (
              <div className={cn("images", { current__image: index === lastIndex })}>
                <img src={imageUrl} alt={`${imageUrl}`} />
              </div>
            ))}
          </div>
        </div>
        <h3 className="wine__subtitle">
          {formatMessage(Messages.wines_subtitle)}
        </h3>
        <button onClick={associate}>
          {formatMessage(Messages.wines_button)}
        </button>
      </>}
    </div>
  );
};
