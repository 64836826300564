import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Messages from "../../../lang/Messages";
import { Breadcrumb } from "../../Commons/Breadcrumb";
import { useLocation } from "react-router-dom";
import "./ApprovedPayment.scss";
import { Alert, Divider } from "@mui/material";
import { ShowBooking } from "../../Commons/ShowBooking";

export const ApprovedPayment = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="approved__container container">
      <ShowBooking />
      <p className="approved__info">{formatMessage(Messages.approved_payment_info)}</p>
      <div className="approved__payment">
        <Alert severity="success">{formatMessage(Messages.approved_payment_alert)}</Alert>
        <p>{formatMessage(Messages.approved_payment_detail)}</p>
        <button>{formatMessage(Messages.approved_payment_button)}</button>
      </div>
    </div>
  );
};
