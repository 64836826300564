import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Pack, Plan, Price, Product } from "../Types";
import Messages from "../../lang/Messages";
import "./ProductCreateEdit.scss";
import { PackForm } from "./PackForm";
import { ProductForm } from "./ProductForm";
import { createProduct, editProduct, uploadImages } from "../../services/endPoints";
import { Avatar, Chip, CircularProgress } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import cn from "classnames";
import moment from "moment-timezone";
import { useLocation, useNavigate } from "react-router-dom";
import { FormDataContext } from "../../App";

const requireFields = ["name", "endDate", "plans", "pack"];

export const ProductCreateEdit = () => {
  const { formatMessage } = useIntl();
  const { state } = useLocation();
  const navigate = useNavigate()
  const { accessToken } = useContext(FormDataContext);
  
  const [newProduct, setNewProduct] = useState<Product>(state ? state : {...{} as Product, endDate: moment().format('YYYY/MM/DD')});
  const [isSaving, setIsSaving] = useState<Boolean>(false);
  const [errors, setErrors] = useState<Record<string, string>>({} as Record<string, string>);
  const [image, setImage] = useState<FormData>(new FormData());
  const [newPrice, setNewPrice] = useState<Plan>({} as Plan)

  moment.tz.setDefault('GMT');

  useEffect(() => {
    if(!accessToken)
      navigate(`/${Messages.route_login.id}`)
  }, [])


  const handleError = (keyError: string, newValue: any, message: string) => {
    let newError = "";

    if (!newValue || (!!newValue && ((typeof newValue === "string" && !newValue.trim()) || (typeof newValue === "number" && newValue === 0))))
      newError = message

    setErrors({ ...errors, [keyError]: newError });
  };

  const handleSelectionChange = (
    key: string,
    newValue: string | number
  ) => {
    setNewProduct({ ...newProduct, [key]: newValue });
  };

  const handleAddPack = (newPack: Pack) => {
    if (!newProduct.pack) newProduct.pack = [];
    newPack = { ...newPack, id: uuidv4() };
    setNewProduct({ ...newProduct, pack: [...newProduct.pack, newPack] });
  };

  const handlePackDelete = (id: string) => {
    const { pack } = newProduct;
    const newPack = pack.filter((data) => data.id !== id);

    setNewProduct({ ...newProduct, pack: newPack });
  };

  const isValidPrice = (newPrice: Plan) => {
    const { plans } = newProduct
    const { startDate, endDate, amount } = newPrice

    const invalidPrice = !amount || amount === '0'

    const currentPlans = plans.filter(plan => !plan.delete)

    let errorMessage = !startDate
    ? formatMessage(Messages.product_price_startDate_error)
    : !endDate
    ? formatMessage(Messages.product_price_endDate_error)
    : currentPlans.find(
        (plan) =>
          moment(newPrice.startDate).isSameOrAfter(moment(plan.startDate).format('YYYY/MM/DD')) &&
          moment(newPrice.startDate).isSameOrBefore(moment(plan.endDate).format('YYYY/MM/DD'))
      )
    ? formatMessage(Messages.product_price_startDate_invalid)
    : endDate &&
      (moment(endDate).isBefore(moment(startDate)) ||
      currentPlans.find(
          (plan) =>
            moment(newPrice.endDate).isSameOrAfter(moment(plan.startDate).format('YYYY/MM/DD')) &&
            moment(newPrice.endDate).isSameOrBefore(moment(plan.endDate).format('YYYY/MM/DD'))
        ))
    ? formatMessage(Messages.product_price_endDate_invalid)
    : invalidPrice ? formatMessage(Messages.product_price_startDate_invalid) : "";

    setErrors({ ...errors, price: errorMessage });  
    return !errorMessage.trim().length     
  }

  const handlePrices = (key: string, newValue: string | number | null) => {
      setNewPrice({ ...newPrice, [key]: newValue })
  }

  const handleAddPrice = (newPrice: Plan) => {
    handleError('price', null, '')
    if (!newProduct.plans) newProduct.plans = [];
    if(!isValidPrice(newPrice)) return
    const newPlans = [...newProduct.plans, newPrice]
    console.log("ADD PLANS", newPlans)
    setNewProduct({ ...newProduct, plans:  newPlans});
    setNewPrice({} as Plan);
  };

  const handlePriceDelete = (id: number) => {
    console.log("ID", id)
    const { plans } = newProduct;
    const priceToDelete = plans.find((plan) => plan.id === id) || {} as Plan
    const newPrices = [ ...plans.filter(plan => plan.id !== id), { ...priceToDelete, delete: true } ]
    console.log("DELETE PLANS", newPrices)
    setNewProduct({ ...newProduct, plans: newPrices });
  }

  const validateForm = () => {
    return !requireFields.filter((field) => !newProduct[field as keyof Product]).length;
  };

  const cleanStates = () => {
    setNewProduct({...{} as Product, endDate: moment().format('YYYY/MM/DD')});
    setNewPrice({} as Plan);
    setImage({} as FormData);
    setIsSaving(false);
  }

  const handleSaveProduct = async () => {
    setIsSaving(true);
    console.log("NEWPRODUCT", newProduct)
    try {
      if(state) {
        const productUpdated = await editProduct({ ...newProduct, imageUrl: state.imageUrl })
        setIsSaving(false);
        navigate(`/${Messages.route_prod_admin.id}`)
        return;
      }
      await uploadImages(image)
      await createProduct(newProduct)
      //await createPlan()
      cleanStates()
      navigate(`/${Messages.route_prod_admin.id}`) 
    } catch (error) {
      setIsSaving(false);
      cleanStates()
    }
  };

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (!file) return;
    let formData = new FormData();
    formData.append("image", file);
    setImage(formData);
    setNewProduct({ ...newProduct, imageUrl: file.name });
  };

  return (
    <div className="productadmin">
      <div className="productadmin__wrapper container">
        <ProductForm
          newProduct={newProduct}
          errors={errors}
          handleSelectionChange={handleSelectionChange}
          handleAddPrice={handleAddPrice}
          handleImageUpload={handleImageUpload}
          handleError={handleError}
          newPrice={newPrice}
          handlePrices={handlePrices}
          handlePriceDelete={handlePriceDelete}
        />
        <div className="product__pack">
          <PackForm handleAddPack={handleAddPack} />
          <h3>Detalle del pack</h3>
          {newProduct.pack && (
          <>
            {newProduct.pack.map(({ id, name, type, quantity }) => (
              <Chip avatar={<Avatar>{type.substring(0, 1)}</Avatar>} label={`${name} ${quantity}`} onDelete={() => handlePackDelete(id)} />
              // <Stack>
              //   <input type="hidden" value={id} />
              //   <Avatar>{type.substring(0, 1)}</Avatar>
              //   <Typography>{`${name} ${type}`}</Typography>
              //   <div className="pack__icon">
              //     <CloseIcon onClick={() => deletePack(id)} />
              //   </div>
              // </Stack>
            ))}
            </>
          )}
        </div>
      </div>
      <div className="productadmin__save">
        <button
          disabled={!validateForm() || !!isSaving}
          className={cn({
            disabled: !!isSaving,
          })} onClick={handleSaveProduct}>
          {formatMessage(Messages.product_save)}
        </button>
        {isSaving && <CircularProgress size={24} />}
      </div>
    </div>
  );
};
