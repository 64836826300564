import { useIntl } from "react-intl";
import Messages from "../../lang/Messages";
import React, { useEffect, useContext, useState } from "react";
import "./Footer.scss";
import { Divider, TextField } from "@mui/material";
import { FormDataContext } from "../../App";
import { createContact, getIpAddress } from "../../services/endPoints";
import { CircularProgress } from "@mui/material";

export const Footer = () => {
  const { formatMessage } = useIntl();
  const { setIsCheckout, isMobile, isMinWidth800, ga4Event } = useContext(FormDataContext);

  const [email, setEmail] = useState<string>("");
  const [isWaiting, setIsWaiting] = useState<Boolean>(false);

  useEffect(() => {
    setIsCheckout(false);
  }, []);

  const subscribe = async (email: string) => {
    setIsWaiting(true);
    ga4Event({
      category: "Information",
      action: 'new_offers_request',
      label: 'Solicitud de novedades y ofertas'
    })
    window.mt("send", "pageview", { page_title: 'subscribe', email });
    const ipAddress = await getIpAddress();
    await createContact({
      email,
      ipAddress: ipAddress.data.ip,
      stage: 1
    })
    setIsWaiting(false);
      // .then(() => {
      //   setIsWaiting(false);
      // })
      // .catch(() => {
      //   setIsWaiting(false);
      // });
  };

  const cancelSubscribe = () => {
    window.mt("send", "pageview", { page_title: "cancelSubscribe" });
  };

  return (
    isWaiting ? <CircularProgress /> :
    <div className="footer__container">
      {isMinWidth800 ? (
        <div className="footer__subscription">
          <TextField
            placeholder={formatMessage(Messages.subscription_input)}
            fullWidth={true}
            variant="outlined"
            onChange={(ev) => setEmail(ev.target.value)}
          />
          <button onClick={() => subscribe(email)}>
            {formatMessage(Messages.subscription_button)}
          </button>
        </div>
      ) : (
        <>
          <div className="footer__subs__container">
            <div className="subscription__text">
              <h3>{formatMessage(Messages.footer_desktop_title)}</h3>
              <p>{formatMessage(Messages.footer_desktop_text)}</p>
            </div>
            <div className="footer__subscription">
              <TextField
                placeholder={formatMessage(Messages.subscription_input)}
                fullWidth={true}
                variant="outlined"
                onChange={(ev) => setEmail(ev.target.value)}
              />
              <button onClick={() => subscribe(email)}>
                {formatMessage(Messages.subscription_button)}
              </button>
            </div>
          </div>
        </>
      )}
      <Divider />
      <div className="footer">
        <div className="footer__texts">
          <div className="footer_about">
            <h3>{formatMessage(Messages.footer_about)}</h3>
            <p>{formatMessage(Messages.footer_club)}</p>
            <p>{formatMessage(Messages.footer_selection)}</p>
            <p>{formatMessage(Messages.footer_contact)}</p>
          </div>
          <div className="footer__discover">
            <h3>{formatMessage(Messages.footer_discover)}</h3>
            <p>{formatMessage(Messages.footer_commit)}</p>
            <p>{formatMessage(Messages.footer_associate)}</p>
            <p>{formatMessage(Messages.footer_payments)}</p>
            <p>{formatMessage(Messages.footer_shipment)}</p>
          </div>
        </div>
        <div className="footer__info">
          {isMobile && <div className="footer__cancel">
            <button onClick={cancelSubscribe}>
              {formatMessage(Messages.cancel_subscription_button)}
            </button>
          </div>}
          {!isMobile && <h3>{formatMessage(Messages.footer_contact_us)}</h3>}
          <div className="footer__social">
            <img src='twitter.svg' alt="twitter" />
            <img src='instagram.svg' alt="instagram" />
            <img src='facebook.svg' alt="facebook" />
          </div>
          <div className="footer_contact">
            <p>{formatMessage(Messages.footer_mail)}</p>
            <p>{formatMessage(Messages.footer_phone)}</p>
          </div>
        </div>
      </div>
        {!isMobile && <div className="footer__cancel">
          <button onClick={cancelSubscribe}>
            {formatMessage(Messages.cancel_subscription_button)}
          </button>
        </div>}
    </div>
  );
};
