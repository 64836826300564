import { useContext } from "react"
import { useIntl } from "react-intl";
import Messages from "../../lang/Messages";
import "./Navbar.scss";
import { useNavigate } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Divider } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { FormDataContext } from "../../App";

export const Navbar = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { isMobile } = useContext(FormDataContext);

  const openMenu = () => {
    const refLinks = document.getElementById("links");

    if (!refLinks) return;

    refLinks.classList.toggle("nav__visible")
  };

  const goTo = (route?: String) => { 
    console.log(route)
    route ? navigate(`/${route}`) : navigate('/')
    openMenu()
  }

  const MobileNavbar = () => {
    return (
      <nav>
        <div className="nav__container">
          <div className="nav__links" id="links">
            <img src='logo.svg' alt="logo" />
            <div className="nav__icons">
              <CloseIcon onClick={() => openMenu()} />
            </div>
            <div className="nav__link">
              <div onClick={()=>{goTo()}}>{formatMessage(Messages.route_home)}</div>
              <ArrowForwardIosIcon />
            </div>
            <Divider />
            <div className="nav__link">
              <div onClick={()=>{goTo(Messages.route_club.id)}}>{formatMessage(Messages.route_club)}</div>
              <ArrowForwardIosIcon onClick={()=>{goTo(Messages.route_club.id)}} />
            </div>
            <Divider />
            <div className="nav__link">
              <div onClick={()=>{goTo(Messages.route_products.id)}}>{formatMessage(Messages.route_products)}</div>
              <ArrowForwardIosIcon />
            </div>
            <Divider />
            <div className="nav__link">
              <div onClick={()=>{goTo(Messages.route_contact.id)}}>{formatMessage(Messages.route_contact)}</div>
              <ArrowForwardIosIcon />
            </div>
            <Divider />
            <div className="nav__link">
              <div onClick={()=>{goTo(Messages.route_about_register.id)}}>{formatMessage(Messages.route_about_register)}</div>
              <ArrowForwardIosIcon />
            </div>  
            <div className="nav__link login">
              <img src='/avatar.svg' alt="avatar" />
              <div onClick={()=>{goTo(Messages.route_login.id)}}>{formatMessage(Messages.route_login)}</div>
            </div>     
          </div>
          <div className="icon" onClick={() => openMenu()}>
            <MenuIcon />
          </div>
        </div>
      </nav>
    );
  }

  const DesktopNavbar = () => {
    return (
      <div className="navbar__container">
        <div className="navbar__link" onClick={()=>{goTo()}}>{formatMessage(Messages.route_home)}</div>        
        <div className="navbar__link" onClick={()=>{goTo(Messages.route_club.id)}}>{formatMessage(Messages.route_club)}</div>
        <div className="navbar__link" onClick={()=>{goTo(Messages.route_products.id)}}>{formatMessage(Messages.route_products)}</div>
        <div className="navbar__link" onClick={()=>{goTo(Messages.route_contact.id)}}>{formatMessage(Messages.route_contact)}</div>
        <div className="navbar__link" onClick={()=>{goTo(Messages.route_about_register.id)}}>{formatMessage(Messages.route_about_register)}</div> 
        <img src='avatar.svg' alt="avatar" onClick={()=>{goTo(Messages.route_login.id)}} />
      </div>
    )
  }

  return (
    <>
      {isMobile ? <MobileNavbar /> : <DesktopNavbar />}
    </>
  );
};
