import { defineMessages } from "react-intl";

export default defineMessages({
  main_title: { id: "main_title", defaultMessage: "CLUB" },  
  route_home: { id: "/", defaultMessage: "Home" },
  route_club: { id: "el_club", defaultMessage: "El club" },
  route_products: { id: "selecciones", defaultMessage: "Selecciones" },
  route_selection_detail: { id: "detalle_producto", defaultMessage: "Detalle producto" },
  route_checkout: { id: "checkout", defaultMessage: "Checkout" },
  route_address: { id: "compra", defaultMessage: "Association" },
  route_confirmation: { id: "confirmación", defaultMessage: "Confirmation" },
  route_rejected_payment: { id: "pago_rechazado", defaultMessage: "Pago rechazado" },
  route_approved_payment: { id: "pago_aprobado", defaultMessage: "Pago aprobado" },
  route_contact: { id: "contacto", defaultMessage: "Contacto" },
  route_about_register: { id: "acerca_registro", defaultMessage: "Como asociarte" },
  route_association: { id: "asociación", defaultMessage: "Asociación" },
  route_register: { id: "register", defaultMessage: "Registro" },
  route_prod_admin: { id: "gestion_productos", defaultMessage: "Gestión productos" },
  route_prod_manage: { id: "admin_producto", defaultMessage: "Crear" },
  
  route_login: { id: "login", defaultMessage: "Registrarme / Iniciar sesión" },
  route_wines: { id: "route_wines", defaultMessage: "Vinos" },
  route_product_admin: { id: "route_product_admin", defaultMessage: "CrearProducto" },
  route_locality_admin: { id: "route_locality_admin", defaultMessage: "CrearLocalidad" },

  home_title_part1: { id: "home_title_part1", defaultMessage: "Los" },
  home_title_part2: { id: "home_title_part2", defaultMessage: " mejores" },
  home_title_part3: { id: "home_title_part3", defaultMessage: "vinos" },
  home_title_part4: { id: "home_title_part4", defaultMessage: " en tu casa" },
  home_description1: { id: "home_description1", defaultMessage: "Buscamos grandes producciones" },
  home_description2: { id: "route_club", defaultMessage: "Armamos tu Selección mensual" },
  home_description3: { id: "route_club", defaultMessage: "La enviamos a tu casa" },
  home_button: { id: "home_button", defaultMessage: "Reservar" },
  
  about_title: { id: "about_title", defaultMessage: "El Club" },  
  about_subtitle: { id: "about_subtitle", defaultMessage: "¿Cómo funciona el Club?" },
  about_title_guide: { id: "about_title_guide", defaultMessage: "Búsqueda de vinos" },
  about_exclusive_wine: { id: "about_exclusive_wine_text", defaultMessage: "Vinos exclusivos" },
  about_exclusive_wine_detail: { id: "about_exclusive_wine_detail", defaultMessage: "Todos los meses buscamos vinos únicos verdaderamente notables seleccionados por especialistas." },
  about_productor: { id: "about_productor", defaultMessage: "Directo de productores" },
  about_productor_detail: { id: "about_productor_detail", defaultMessage: "Encontrando productores dedicados a entregarnos expresiones excelentes de vinos argentinos." },
  about_price: { id: "about_price", defaultMessage: "Precios irresistibles" },
  about_price_detail: { id: "about_price_detail", defaultMessage: "Por eso nuestros socios pueden obtenerlos a precios increíblemente inferiores." },
  about_box_title: { id: "about_box_title", defaultMessage: "Armamos tu caja" },
  about_box_subtitle: { id: "about_box_subtitle", defaultMessage: "Cajas del mes" },
  about_box_reserved: { id: "about_box_reserved", defaultMessage: "Una caja reservada" },
  about_box_reserved_detail: { id: "about_box_reserved_detail", defaultMessage: "Cada mes nuestros socios tienen reservada una caja de nuestra selección." },
  about_box_variety: { id: "about_box_variety", defaultMessage: "Variedad" },
  about_box_variety_detail: { id: "about_box_variety_detail", defaultMessage: "En cada Caja Selección del mes habrá Malbec, más otras variedades para que los socios puedan disfrutar de una gran variedad de sabores, descubran y amplíen su experiencia." },
  about_box_alternative: { id: "about_box_alternative", defaultMessage: "Cajas alternativas" },
  about_box_alternative_detail: { id: "about_box_alternative_detail", defaultMessage: "Ofrecemos para que puedas optar por cajas de vino armadas con botellas de distintas bodegas. Caja premium, Caja Blancos, Caja Degustación (con vinos de nuestra selección junto con vinos de etiqueta de otras bodegas)." },
  about_box_change: { id: "about_box_change", defaultMessage: "Posibilidad de cambiar" },
  about_box_change_detail: { id: "about_box_change_detail", defaultMessage: "Sin importar que caja del mes tengas reservada, podrás cambiarla por otra en cualquier momento." },
  about_box_add: { id: "about_box_add", defaultMessage: "Posibilidad de agregar" },
  about_box_add_detail: { id: "about_box_add_detail", defaultMessage: "Podrás agregar las cajas que desees siempre que exista stock para entregar." },
  about_club_commit: { id: "about_club_commit", defaultMessage: "Sin compromisos" },
  about_club_commit_subtitle1: { id: "about_club_commit_subtitle1", defaultMessage: "Sin obligación de recibir" },
  about_club_commit_detail1: { id: "about_club_commit_detail1", defaultMessage: "Los socios pueden suspender el mes que así lo deseen, no se le enviará ni cobrará el mes en cuestión." },
  about_club_associate: { id: "about_club_associate", defaultMessage: "¿Cómo me asocio?" },
  about_club_associate_subtitle1: { id: "about_club_associate_subtitle1", defaultMessage: "Registro online" },
  about_club_associate_detail1: { id: "about_club_associate_detail1", defaultMessage: "Podés registrarte en este sitio web, cargando sólo tus datos personales, de lugar de entrega y medios de pago." },
  about_club_associate_subtitle2: { id: "about_club_associate_subtitle2", defaultMessage: "Registro con un asesor" },
  about_club_associate_detail2: { id: "about_club_associate_detail2", defaultMessage: "En caso de querer comunicarte personalmente con nosotros, dejanos tus datos acá y nos contactaremos." },
  about_club_payments: { id: "about_club_payments", defaultMessage: "Medios de pago" },
  about_club_payments_subtitle1: { id: "about_club_payments_subtitle1", defaultMessage: "Todas las tarjetas" },
  about_club_payments_detail1: { id: "about_club_payments_detail1", defaultMessage: "Para que los socios no tengan que molestarse en realizar los pagos cada mes, se les debitará automáticamente de su tarjeta de crédito o débito una vez que reciban sus cajas." },
  about_club_shipment: { id: "about_club_shipment", defaultMessage: "Envíos" },
  about_club_shipment_subtitle1: { id: "about_club_shipment_subtitle1", defaultMessage: "A todo el país" },
  about_club_shipment_detail1: { id: "about_club_shipment_detail1", defaultMessage: "Entregamos en cada rincón de Argentina." },
  about_club_shipment_subtitle2: { id: "about_club_shipment_subtitle2", defaultMessage: "¿Cuándo entregamos?" },
  about_club_shipment_detail2: { id: "about_club_shipment_detail2", defaultMessage: "Cada mes alrededor del día 10, nuestras cajas viajan a todos los rincones del país." },
  about_club_shipment_subtitle3: { id: "about_club_shipment_subtitle3", defaultMessage: "Primer entrega" },
  about_club_shipment_detail3: { id: "about_club_shipment_detail3", defaultMessage: "Para que los socios no tengan que esperar se les enviará en los próximos días a su asociación." },

  selections_title: { id: "selections_title", defaultMessage: "Selecciones" },
  unico: { id: "selections_unico", defaultMessage: "Único anteriores" },
  degustacion: { id: "selections_premium", defaultMessage: "Degustación anteriores" },
  blancos: { id: "selections_premium", defaultMessage: "Blancos anteriores" },
  premium: { id: "selections_premium", defaultMessage: "Premium anteriores" },
  selections_button: { id: "selections_button", defaultMessage: "Quiero ser socio" },

  selection_detail_back_title: { id: "selection_detail_back_title", defaultMessage: "Home / Selecciones" },
  selection_detail_button: { id: "selection_detail_button", defaultMessage: "Reservar" },
  selection_detail_list_price: { id: "selection_detail_list_price", defaultMessage: "Precio de lista" },
  selection_detail_member_price: { id: "selection_detail_member_price", defaultMessage: "Precio socios" },
  selection_detail_total_price: { id: "selection_detail_total_price", defaultMessage: "Total" },
  selection_detail_decription: { id: "selection_detail_decription", defaultMessage: "Descripción" },
  selection_detail_pack: { id: "selection_detail_pack", defaultMessage: "Caja x 6 unidades." },

  checkout_back_title: { id: "checkout_back_title", defaultMessage: "Volver a Selecciones / Producto" },
  checkout_title: { id: "checkout_title", defaultMessage: "Checkout Reservar" },
  checkout_total: { id: "checkout_total", defaultMessage: "Total con envío" },
  checkout_button: { id: "checkout_button", defaultMessage: "Iniciar reserva" },
  checkout_button_next: { id: "checkout_button_next", defaultMessage: "Siguiente" },
  checkout_link: { id: "checkout_link", defaultMessage: "Seguir comprando" },
  checkout_link_cancel: { id: "checkout_link_cancel", defaultMessage: "Cancelar reserva" },
  checkout_resume_title: { id: "checkout_resume_title", defaultMessage: "Resumen de tu compra" },
  checkout_resume_subtotal: { id: "checkout_resume_subtotal", defaultMessage: "Subtotal" },
  checkout_resume_shipment_price: { id: "checkout_resume_shipment_price", defaultMessage: "Costo de envío" },

  association_back_title: { id: "association_back_title", defaultMessage: "Volver a Checkout" },
  association_link: { id: "association_link", defaultMessage: "Ver mi reserva" },
  association_email: { id: "association_email", defaultMessage: "Email" },
  association_login: { id: "association_login", defaultMessage: "¿Ya tenés una cuenta? Iniciá sesión (Opcional.)" },
  association_promo_check: { id: "association_promo_check", defaultMessage: "Quiero recibir promociones y novedades" },
  association_data_title: { id: "association_data_title", defaultMessage: "Datos personales" },
  association_dni: { id: "association_dni", defaultMessage: "DNI" },
  association_complete_name: { id: "association_complete_name", defaultMessage: "Nombre y apellido" },
  association_phone: { id: "association_phone", defaultMessage: "Número de teléfono" },
  association_address: { id: "association_address", defaultMessage: "Domicilio" },
  association_floor: { id: "association_floor", defaultMessage: "Piso" },
  association_door: { id: "association_door", defaultMessage: "Departamento" },
  association_opcional: { id: "association_door_abbr", defaultMessage: "(opcional)" },
  association_city: { id: "association_city", defaultMessage: "Ciudad" },
  association_province: { id: "association_province", defaultMessage: "Provincia" },
  association_postal_code: { id: "association_postal_code", defaultMessage: "Código postal" },
  association_notes: { id: "association_notes", defaultMessage: "Notas del pedido (opcional)" },
  association_next_button: { id: "association_next_button", defaultMessage: "Siguiente" },
  association_cancel_link: { id: "association_cancel_link", defaultMessage: "Cancelar reserva" },
  association_complete_name_error: { id: "association_complete_name_error", defaultMessage: "Debe ingresar nombre y apellido" },
  association_phone_error: { id: "association_phone_error", defaultMessage: "Debe ingresar un nro telefónico de contacto" },
  association_email_error: { id: "association_email_error", defaultMessage: "Debe ingresar un email" },
  association_address_error: { id: "association_address_error", defaultMessage: "Debe ingresar la dirección de envío" },
  association_city_error: { id: "association_city_error", defaultMessage: "Debe seleccionar una ciudad" },
  association_province_error: { id: "association_province_error", defaultMessage: "Debe seleccionar una provincia" },
  association_postal_code_error: { id: "association_postal_code_error", defaultMessage: "Debe ingresar el código postal" },
  association_dni_helper: { id: "association_dni_helper", defaultMessage: "Número de documento" },
  association_completeName_helper: { id: "association_completeName_helper", defaultMessage: "Nombre y apellido" },
  association_email_helper: { id: "association_email_helper", defaultMessage: "Email" },
  association_phone_helper: { id: "association_phone_helper", defaultMessage: "Teléfono" },
  association_address_helper: { id: "association_address_helper", defaultMessage: "Domicilio (Calle y número)" },
  association_floor_helper: { id: "association_floor_helper", defaultMessage: "Piso (opcional)" },
  association_door_helper: { id: "association_door_helper", defaultMessage: "Dpto (opcional)" },
  association_city_helper: { id: "association_city_helper", defaultMessage: "Ciudad" },
  association_province_helper: { id: "association_province_helper", defaultMessage: "Provincia" },
  association_postalCode_helper: { id: "association_postalCode_helper", defaultMessage: "Código postal" },

  confirmation_title: { id: "confirmation_title", defaultMessage: "Felicitaciones🎉🎉 Bienvenido al club!!🍷🍷" },
  confirmation_back_title: { id: "confirmation_back_title", defaultMessage: "Volver a Datos personales" },
  confirmation_sent: { id: "confirmation_sent", defaultMessage: "Envío" },
  confirmation_email: { id: "confirmation_email", defaultMessage: "Email" },
  confirmation_method: { id: "confirmation_method", defaultMessage: "Método" },
  confirmation_method_value: { id: "confirmation_method_value", defaultMessage: "Envío a domicilio" },
  confirmation_to: { id: "confirmation_to", defaultMessage: "Destinatario" },
  confirmation_address: { id: "confirmation_address", defaultMessage: "Domicilio" },
  confirmation_change: { id: "confirmation_change", defaultMessage: "Cambiar" },
  confirmation_payment_title: { id: "confirmation_payment_title", defaultMessage: "Pago" },
  confirmation_payment_method: { id: "confirmation_payment_method", defaultMessage: "Método" },
  confirmation_payment_text: { id: "confirmation_payment_method", defaultMessage: "Todos los datos ingresados son seguros y únicamente utilizados por Mercado Pago, una plataforma de pago altamente segura" },
  confirmation_payment_button: { id: "confirmation_payment_button", defaultMessage: "Pagar reserva" },
  
  reject_payment_info: { id: "reject_payment_info", defaultMessage: "Esperando pago" },
  reject_payment_alert: { id: "reject_payment_alert", defaultMessage: "No se registró ningún pago." },
  reject_payment_detail: { id: "reject_payment_detail", defaultMessage: 'Podés volver a intentar el pago haciendo click en "Reintentar pago."' },
  reject_payment_button: { id: "reject_payment_button", defaultMessage: "Reintentar pago" },

  approved_payment_info: { id: "approved_payment_info", defaultMessage: "Pago realizado" },
  approved_payment_alert: { id: "approved_payment_alert", defaultMessage: "El pago fue realizado con éxito" },
  approved_payment_detail: { id: "approved_payment_detail", defaultMessage: "Ya podés comenzar a disfrutar todos los meses de las mejores selecciones de vinos." },
  approved_payment_button: { id: "approved_payment_button", defaultMessage: "Ver otras selecciones" },

  shipment_cp_title: { id: "shipment_cp_title", defaultMessage: "Ingresá tu código postal" },  
  shipment_info: { id: "shipment_info", defaultMessage: "El costo de envío se sumará a la selección" },
  shipment_input_placeholder: { id: "shipment_input_placeholder", defaultMessage: "Código postal" },
  shipment_send_button: { id: "shipment_send_button", defaultMessage: "Enviar" },

  club_title: { id: "club_title_part1", defaultMessage: "El club" },
  club_card_title1: { id: "club_card_title1", defaultMessage: "Vinos exclusivos" },
  club_card_title2: { id: "club_card_title2", defaultMessage: "Directo de productores" },
  club_card_title3: { id: "club_card_title3", defaultMessage: "Precios irresistibles" },
  club_card_description1: { id: "club_card_description1", defaultMessage: "Todos los meses buscamos vinos únicos, verdaderamente notables seleccionados por especialistas." },
  club_card_description2: { id: "club_card_description2", defaultMessage: "Encontrando productores dedicados a entregarnos expresiones excelentes de vinos argentinos." },
  club_card_description3: { id: "club_card_description3", defaultMessage: "Por eso nuestros socios pueden obtenerlos a precios increíblemente inferiores." },

  benefits_title1: { id: "benefits_title1", defaultMessage: "{CUMPLIMOS " },
  benefits_title2: { id: "benefits_title2", defaultMessage: "8 años" },
  benefits_title3: { id: "benefits_title3", defaultMessage: "}" },
  benefits_subtitle: { id: "benefits_subtitle", defaultMessage: "Beneficios" },
  benefits_detail1: { id: "benefits_detail1", defaultMessage: "Tomarás vinos inaccesibles" },
  benefits_detail2: { id: "benefits_detail2", defaultMessage: "Seleccionados de gran calidad" },
  benefits_detail3: { id: "benefits_detail3", defaultMessage: "A muy bajo costo" },
  benefits_detail4: { id: "benefits_detail4", defaultMessage: "Probarás nuevas experiencias" },
  benefits_info: { id: "benefits_info", defaultMessage: "+ Info" },

  wines_title: { id: "wines_title", defaultMessage: "Selección Mensual" },
  wines_jan: { id: "wines_jan", defaultMessage: "Enero" },
  wines_feb: { id: "wines_feb", defaultMessage: "Febrero" },
  wines_mar: { id: "wines_mar", defaultMessage: "Marzo" },
  wines_apr: { id: "wines_apr", defaultMessage: "Abril" },
  wines_may: { id: "wines_may", defaultMessage: "Mayo" },
  wines_jun: { id: "wines_jun", defaultMessage: "Junio" },
  wines_jul: { id: "wines_jul", defaultMessage: "Julio" },
  wines_aug: { id: "wines_aug", defaultMessage: "Agosto" },
  wines_sep: { id: "wines_sep", defaultMessage: "Septiembre" },
  wines_oct: { id: "wines_oct", defaultMessage: "Octubre" },
  wines_nov: { id: "wines_nov", defaultMessage: "Noviembre" },
  wines_dec: { id: "wines_dec", defaultMessage: "Diciembre" },  
  wines_subtitle: { id: "wines_subtitle", defaultMessage: "Promoción Nuevos Socios" },
  wines_button: { id: "wines_button", defaultMessage: "Quiero ser socio" },

  reviews__title: { id: "reviews__title", defaultMessage: "Reseñas" },
  reviews__button: { id: "reviews__button", defaultMessage: "Ver más" },

  subscription_input: { id: "subscription_input", defaultMessage: "Ingresa tu email" },
  subscription_input_desc: { id: "subscription_input_desc", defaultMessage: "Ingresa tu email" },
  subscription_button: { id: "subscription_button", defaultMessage: "QUIERO SUSCRIBIRME" },  
  cancel_subscription_button: { id: "cancel_subscription_button", defaultMessage: "Botón de arrepentimiento" },

  footer_about: { id: "footer_about", defaultMessage: "NOSOTROS" },
  footer_discover: { id: "footer_discover", defaultMessage: "DESCUBRI" },
  footer_club: { id: "footer_club", defaultMessage: "El club" },
  footer_selection: { id: "footer_selection", defaultMessage: "Selecciones" },
  footer_contact: { id: "footer_contact", defaultMessage: "Contacto" },
  footer_commit: { id: "footer_commit", defaultMessage: "Sin compromisos" },
  footer_associate: { id: "footer_associate", defaultMessage: "Como me asocio" },
  footer_payments: { id: "footer_payments", defaultMessage: "Medios de pago" },
  footer_shipment: { id: "footer_shipment", defaultMessage: "Envíos" },
  footer_mail: { id: "footer_mail", defaultMessage: "info@clubdevinos.com.ar" },
  footer_phone: { id: "footer_phone", defaultMessage: "0800-345-WINE (9463)" },
  footer_desktop_title: { id: "footer_desktop_title", defaultMessage: "Recibí novedades y ofertas" },
  footer_desktop_text: { id: "footer_desktop_text", defaultMessage: "Ponete en contacto con nosotros para recibir ofertas, novedades, degustaciones y mucho más." },
  footer_contact_us: { id: "footer_contact_us", defaultMessage: "CONTACTANOS" },

  club_title2: { id: "club_title2", defaultMessage: "Lo que necesitás conocer sobre nuestro Club" },
  club_detail_title1: { id: "club_detail_title1", defaultMessage: "Caja del mes" },
  club_detail_description1_1: { id: "club_detail_description1_1", defaultMessage: "Una caja reservada: Cada mes nuestros socios tienen reservada una caja de nuestra selección." },
  club_detail_description1_2: { id: "club_detail_description1_2", defaultMessage: "Variedad: En cada Caja Selección del mes habrá Malbec, mas otras variedades para que los socios puedan disfrutar de una gran variedad de sabores, descubran y amplíen su experiencia." },
  club_detail_description1_3: { id: "club_detail_description1_3", defaultMessage: "Cajas alternativas: Ofrecemos para que puedas optar, cajas de vinos armadas con botellas de distintas bodegas. Caja Premium, Caja Blancos, Caja degustación (con vinos de nuestra selección junto con vinos de etiquetas de otras bodegas)." },
  club_detail_description1_4: { id: "club_detail_description1_4", defaultMessage: "Posibilidad de cambiar: Sin importar que caja reservada tenga el socio cualquier mes, puede cambiarla por cualquier otra en el momento que desee." },
  club_detail_description1_5: { id: "club_detail_description1_5", defaultMessage: "Posibilidad de agregar: Pueden adicionar las cajas que deseen siempre que exista stock para entregar." },

  club_detail_title2: { id: "club_detail_title2", defaultMessage: "Como me asocio?" },
  club_detail_description2_1: { id: "club_detail_description2_1", defaultMessage: "Registro online: Puedes registrarte en la página solo cargando tus datos personales, lugar de envío y medios de pagos." },
  club_detail_description2_2: { id: "club_detail_description2_2", defaultMessage: "Registro con un asesor: en caso de querer comunicarte personalmente con nosotros, dejanos tus datos y nos contactaremos" },

  club_detail_title3: { id: "club_detail_title3", defaultMessage: "Medios de pagos" },
  club_detail_description3_1: { id: "club_detail_description3_1", defaultMessage: "Todas las tarjetas, para que los socios no tengan que molestarse en realizar los pagos cada mes, sino que se le debitará automáticamente de su tarjeta de crédito o débito una vez que reciban sus cajas." },

  club_detail_title4: { id: "club_detail_title4", defaultMessage: "Envíos" },
  club_detail_description4_1: { id: "club_detail_description4_1", defaultMessage: "A todo el país. Entregamos en cada rincón de Argentina." },
  club_detail_description4_2: { id: "club_detail_description4_2", defaultMessage: "Cuando entregamos: Cada mes alrededor del día 10, nuestras cajas viajan a todos los rincones del país." },
  club_detail_description4_3: { id: "club_detail_description4_3", defaultMessage: "Primer entrega: para que los nuevos socios no tengan que esperar se les enviará en los próximos días a su asociación." },
  club_detail_description4_4: { id: "club_detail_description4_4", defaultMessage: "Sin obligación de recibir: Los socios pueden suspender el mes que así lo deseen, y no se les enviará ni cobrará dicho mes." },
  
  products_title: { id: "products_title", defaultMessage: "Cumplimos 8 años" },
  product_history_title: { id: "product_history_title", defaultMessage: "Beneficios de asociarte a nuestro Club" },
  product_history1: { id: "product_history1", defaultMessage: "Acceder a vinos exclusivos difíciles de conseguir en comercios especializados o vinotecas y con precios considerablemente inferiores." },
  product_history2: { id: "product_history2", defaultMessage: "Vinos que pasan por un proceso de selección que garantiza calidad." },
  product_history3: { id: "product_history3", defaultMessage: "Acceder a variedad de vinos, que muchos no comprarían de no estar seleccionados por el club, en cuanto a experimentar nuevas experiencias se refiere." },
  product_history_button:{ id: "product_history_button", defaultMessage: "Comentarios de socios" },
  products_body_title: { id: "products_body_title", defaultMessage: "Elegí tu selección mensual" },
  product_list_price: { id: "product_list_price", defaultMessage: "Lista" },
  product_member_price: { id: "product_member_price", defaultMessage: "Socios" },
  products_button: { id: "products_button", defaultMessage: "RESERVAR" },

  product_detail_location_title: { id: "product_detail_location_title", defaultMessage: "Datos de envío" },
  product_detail_province: { id: "product_detail_province", defaultMessage: "Selecciona la provincia" },
  product_detail_city: { id: "product_detail_city", defaultMessage: "Selecciona la ciudad" },
  product_detail_city_label: { id: "product_detail_city_label", defaultMessage: "Ingresa la ciudad" },
  product_detail_calculate_shipment: { id: "product_detail_calculate_shipment", defaultMessage: "Enviar" },
  product_detail_address_label: { id: "product_detail_address_label", defaultMessage: "Código postal" },
  product_detail_province_error: { id: "product_detail_province_error", defaultMessage: "Debe seleccionar una provincia" },
  product_detail_city_error: { id: "product_detail_city_error", defaultMessage: "Debe ingresar una ciudad válida" },
  product_detail_address_error: { id: "product_detail_address_error", defaultMessage: "Debe ingresar una dirección válida" },
  product_detail_buy: { id: "product_detail_buy", defaultMessage: "LO QUIERO" },
  product_un: { id: "product_un", defaultMessage: "Único" },
  product_pr: { id: "product_pr", defaultMessage: "Premium" },
  product_dg: { id: "product_dg", defaultMessage: "Degustación" },
  product_bl: { id: "product_bl", defaultMessage: "Blancos" },

  checkout_payment_title: { id: "checkout_payment_title", defaultMessage: "Medios de pago disponibles" },
  checkout_payment_button: { id: "checkout_payment_button", defaultMessage: "Pagar" },
  checkout_shipment_price: { id: "checkout_shipment_price", defaultMessage: "Costo envío" },
  checkout_back_button: { id: "checkout_back_button", defaultMessage: "Anterior" },
  checkout_next_button: { id: "checkout_next_button", defaultMessage: "Siguiente" },
  checkout_finish_button: { id: "checkout_finish_button", defaultMessage: "Finalizar" },

  wines_history_title: { id: "wines_history_title", defaultMessage: "Nuestras selecciones anteriores" },
  wines_history_selection: { id: "wines_history_selection", defaultMessage: "Selecciones" },
  wines_history_older: { id: "wines_history_older", defaultMessage: "Anteriores" },
  wines_history_unico: { id: "wines_history_unico", defaultMessage: "Único" },
  wines_history_premium: { id: "wines_history_premium", defaultMessage: "Premium" },
  wines_future_title: { id: "wines_future_title", defaultMessage: "Lo que se viene" },

  product_admin_title: { id: "product_admin_title", defaultMessage: "Crear un producto" },
  product_selection_name: { id: "product_selection_name", defaultMessage: "Nombre de la selección" },
  product_selection_description: { id: "product_selection_description", defaultMessage: "Descripción de la selección" },
  product_selection_category: { id: "product_selection_category", defaultMessage: "Categoría" },
  product_selection_endDate: { id: "product_selection_endDate", defaultMessage: "Fecha fin" },
  product_selection_listPrice: { id: "product_selection_listPrice", defaultMessage: "Precio de lista" },
  product_selection_memberPrice: { id: "product_selection_memberPrice", defaultMessage: "Precio de socio" },
  product_save: { id: "product_save", defaultMessage: "Guardar" },
  
  product_selection_price_detail: { id: "product_selection_price_detail", defaultMessage: "Historial de precios" },
  product_selection_name_error: { id: "product_selection_name_error", defaultMessage: "Debe ingresar un nombre" },
  product_selection_endDate_error: { id: "product_selection_endDate_error", defaultMessage: "Debe ingresar una fecha" },
  product_selection_price_error: { id: "product_selection_listPrice_error", defaultMessage: "Debe ingresar un precio" },
  product_category_error: { id: "product_category_error", defaultMessage: "Debe seleccionar una categoría" },
  
  pack_title: { id: "pack_title", defaultMessage: "Agrega los packs de la selección" },
  pack_name: { id: "pack_name", defaultMessage: "Nombre del vino" },
  pack_type: { id: "pack_type", defaultMessage: "Tipo del vino" },
  pack_quantity: { id: "pack_quantity", defaultMessage: "Cantidad" },
  pack_description: { id: "pack_description", defaultMessage: "Descripción" },
  pack_name_error: { id: "pack_type_error", defaultMessage: "Debe ingresar un nombre de vino" },
  pack_type_error: { id: "pack_type_error", defaultMessage: "Debe ingresar un tipo" },
  pack_quantity_error: { id: "pack_quantity_error", defaultMessage: "Debe ingresar una cantidad" },
  pack_description_error: { id: "pack_description_error", defaultMessage: "Debe completar la descripción" },
  pack_add_button: { id: "pack_add_button", defaultMessage: "Agregar" },

  product_price_startDate: { id: "product_price_startDate", defaultMessage: "Fecha inicio" },
  product_price_startDate_error: { id: "product_price_startDate_error", defaultMessage: "Debe ingresar una fecha inicio" },
  product_price_endDate: { id: "product_price_endDate", defaultMessage: "Fecha fin" },
  product_price_endDate_error: { id: "product_price_endDate_error", defaultMessage: "Debe ingresar una fecha fin" },
  product_price_startDate_invalid: { id: "product_price_startDate_invalid", defaultMessage: "La fecha de inicio es inválida" },
  product_price_endDate_invalid: { id: "product_price_endDate_invalid", defaultMessage: "La fecha de fin es inválida" },
  product_price_price_invalid: { id: "product_price_price_invalid", defaultMessage: "Debe ingresar un precio" },

  locality_admin_title: { id: "locality_admin_title", defaultMessage: "Crear un producto" },
  locality_name: { id: "locality_name", defaultMessage: "Nombre de la localidad" },
  locality_province: { id: "locality_province", defaultMessage: "Nombre de la provincia" },
  locality_description: { id: "locality_description", defaultMessage: "Descripción" },
  locality_cost: { id: "locality_cost", defaultMessage: "Costo de envío" },
  locality_save: { id: "locality_save", defaultMessage: "Grabar" },
  locality_name_error: { id: "locality_name_error", defaultMessage: "Debe ingresar un nombre" },
  locality_province_error: { id: "locality_province_error", defaultMessage: "Debe ingresar una provincia" },
  locality_cost_error: { id: "locality_cost_error", defaultMessage: "Debe ingresar un costo" },

  locality_item_name: { id: "locality_item_name", defaultMessage: "Localidad" },
  locality_item_description: { id: "locality_item_description", defaultMessage: "Descripción" },
  locality_item_cost: { id: "locality_item_cost", defaultMessage: "Costo de envío" },

  checkout_shipment_first_step: { id: "checkout_shipment_first_step", defaultMessage: "En preparación" },
  checkout_shipment_second_step: { id: "checkout_shipment_second_step", defaultMessage: "En viaje" },
  checkout_shipment_third_step: { id: "checkout_shipment_third_step", defaultMessage: "Finalizado" },

  contact_title:  { id: "contact_title", defaultMessage: "Contacto" },
  contact_name:  { id: "contact_name", defaultMessage: "Nombre" },
  contact_name_label:  { id: "contact_name_label", defaultMessage: "Ingresa tu nombre" },
  contact_name_error:  { id: "contact_name_error", defaultMessage: "Debes ingresar tu nombre" },
  contact_lastName:  { id: "contact_lastName", defaultMessage: "Apellido" },
  contact_lastName_label:  { id: "contact_lastName_label", defaultMessage: "Ingresa tu apellido" },
  contact_email:  { id: "contact_email", defaultMessage: "Email" },
  contact_email_label:  { id: "contact_email_label", defaultMessage: "Ingresa tu email" },
  contact_email_error:  { id: "contact_email_error", defaultMessage: "Debes ingresar tu email de contacto" },
  contact_phone:  { id: "contact_phone", defaultMessage: "Teléfono" },
  contact_phone_label:  { id: "contact_phone_label", defaultMessage: "Ingresa tu número de teléfono" },
  contact_notes:  { id: "contact_notes", defaultMessage: "Dejanos acá tus dudas y te vamos a responder lo más rápido posible." },
  contact_notes_error:  { id: "contact_notes_error", defaultMessage: "Debes ingresar tu consulta" },
  contact_button:  { id: "contact_button", defaultMessage: "Enviar" },

  about_register_title:  { id: "about_register_title", defaultMessage: "Como asociarse" },
  about_register_online_title:  { id: "about_register_online_title", defaultMessage: "Registro online" },
  about_register_online_text:  { id: "about_register_online_text", defaultMessage: "Podés registrarte en este sitio web, cargando solo tus datos personales, de lugar de entrega y medios de pago." },
  about_register_online_button:  { id: "about_register_online_button", defaultMessage: "Quiero ser socio" },
  about_register_manual_title:  { id: "about_register_manual_title", defaultMessage: "Registro con un asesor" },
  about_register_manual_text:  { id: "about_register_manual_text", defaultMessage: "En caso de querer comunicarte personalmente con nosotros, dejanos tus datos acá y nos contactaremos." },
  about_register_manual_button:  { id: "about_register_manual_button", defaultMessage: "Quiero contactarme" },

  register_title:  { id: "register_title", defaultMessage: "Asociarse" },
  register_subtitle:  { id: "register_subtitle", defaultMessage: "Registro online" },
  register_form_name:  { id: "register_form_name", defaultMessage: "Nombre" },
  register_form_selections:  { id: "register_form_selections", defaultMessage: "Selecciones" },
  register_un: { id: "register_un", defaultMessage: "Único" },
  register_dg: { id: "register_dg", defaultMessage: "Degustación" },
  register_bl: { id: "register_bl", defaultMessage: "Blancos" },
  register_pr: { id: "register_pr", defaultMessage: "Premium" },
  register_form_name_label:  { id: "register_form_name_label", defaultMessage: "Ingresa tu nombre" },
  register_form_name_error:  { id: "register_form_name_error", defaultMessage: "Debes ingresar tu nombre" },
  register_form_lastName:  { id: "register_form_lastName", defaultMessage: "Apellido" },
  register_form_lastName_label:  { id: "register_form_lastName_label", defaultMessage: "Ingresa tu apellido" },
  register_form_lastName_error:  { id: "register_form_lastName_error", defaultMessage: "Debes ingresar tu apellido" },
  register_form_email:  { id: "register_form_email", defaultMessage: "Email" },
  register_form_email_label:  { id: "register_form_email_label", defaultMessage: "Ingresa tu email" },
  register_form_email_error:  { id: "register_form_email_error", defaultMessage: "Debes ingresar un email de contacto" },
  register_form_address:  { id: "register_form_address", defaultMessage: "Dirección de entrega" },
  register_form_address_label:  { id: "register_form_address_label", defaultMessage: "Ingresa una dirección de entrega" },
  register_form_address_error:  { id: "register_form_address_error", defaultMessage: "Debes ingresar una dirección de entrega" },
  register_form_phone:  { id: "register_form_phone", defaultMessage: "Teléfono" },
  register_form_phone_label:  { id: "register_form_phone_label", defaultMessage: "Ingresa tu número de teléfono" },
  register_form_phone_error:  { id: "register_form_phone_error", defaultMessage: "Debes ingresar un teléfono de contacto" },
  register_payment_title:  { id: "register_payment_title", defaultMessage: "Método de Pago" },
  association_button:  { id: "register_button", defaultMessage: "Asociarme" },
  
  login_button:  { id: "login_button", defaultMessage: "Iniciar sesión" },
  register_button:  { id: "register_button", defaultMessage: "Registrarme" },

  payment_button:  { id: "payment_button", defaultMessage: "Pagar" },
  payment_loading:  { id: "payment_loading", defaultMessage: "Cargando..." },
});
