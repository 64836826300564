import { useNavigate } from "react-router-dom";
import "./ShowBooking.scss";
import { Divider } from "@mui/material";
import { useIntl } from "react-intl";
import Messages from "../../lang/Messages";
import "./ShowBooking.scss"

export const ShowBooking = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  return (
    <div className="showbooking__container">
      <Divider />
      <div className="showbooking__link">
        <img src='shopGray.svg' alt="shop" />
        <p>{formatMessage(Messages.association_link)}</p>
      </div>
      <Divider />
    </div>
  );
};
