import { useIntl } from "react-intl";
import Messages from "../../lang/Messages";
import "./Club.scss";
import { Divider } from "@mui/material";

export const Club = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="club__container">
      <h2 className="club__title">{formatMessage(Messages.club_title)}</h2>
      <div className="body__instructions">
        <div className="body__instruction">
          <img src='landscape.svg' alt="landscape" />
          <p>{formatMessage(Messages.home_description1)}</p>
        </div>
        <Divider />
        <div className="body__instruction">
          <img src='wines.svg' alt="wines" />
          <p>{formatMessage(Messages.home_description2)}</p>
        </div>
        <Divider />
        <div className="body__instruction">
          <img src='delivery.svg' alt="delivery" />
          <p>{formatMessage(Messages.home_description3)}</p>
        </div>
      </div>
    </div>
  );
};
