import type { AxiosError, AxiosResponse } from 'axios';
import { apiMauticInstance, apiInstanceExternal, apiNodeInstance } from "./apiInterceptors";

type ApiPayload = {
  action: string;
  params?: string | Record<string, string | number> | object;
  data?: object;
  endpoint: string;
  group?: boolean;
  baseURL?: string;
  headers?: Record<string, string>
}

const api = {
  getParams(params: string | Record<string, string | number> | object, group = false) {
    if (typeof params == 'object' && !group) {
      const keys: string[] = Object.keys(params);
      return keys.map(key => { return `${key}=${params[key as keyof object]}` }).join("&");
    }
    else return params;
  },
  async run(payload: ApiPayload) {
    const { action, params, data, endpoint, group } = payload

    const apiRun = action === 'get' ? apiMauticInstance.get : action === 'post' ? apiMauticInstance.post : action === 'patch' ? apiMauticInstance.patch : apiMauticInstance.delete;
    //const url = payload.endpoint ? `${payload.entity}/${payload.endpoint}` : payload.entity

    if (params) {
      const qsParams = this.getParams(params, group)
      
      return apiRun(`${endpoint}${qsParams}`)
        .then((response: AxiosResponse) => {
          return response
        })
        .catch((error: AxiosError) => {
          throw error;
        });
    }    

    return apiRun(endpoint, data)
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  },
  async uploadImage(payload: ApiPayload) {
    const { data, endpoint } = payload
    return apiNodeInstance.post(endpoint, data)
      .then((response: AxiosResponse) => {
        return response
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  },

  async googleLogin(payload: ApiPayload) {
    const { data, endpoint, action } = payload
    console.log(action)
    const api = data ? apiNodeInstance.post: (action !== 'delete') ? apiNodeInstance.get : apiNodeInstance.delete
    return api(endpoint, data)
      .then((response: AxiosResponse) => {
        return response
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  },

  async runExternal(payload: ApiPayload) {
    const { baseURL, endpoint, params, data, headers } = payload

    const api = data ? apiInstanceExternal.post: apiInstanceExternal.get

    if (params) {
      const qsParams = this.getParams(params)

      return api(`${endpoint}?${qsParams}`)
        .then((response: AxiosResponse) => {
          return response
        })
        .catch((error: AxiosError) => {
          throw error;
        });
    }   

    return api(endpoint, headers)
      .then((response: AxiosResponse) => {
        return response
      })
      .catch((error: AxiosError) => {
       // console.log(error);
        throw error;
      });
  }
};

export default api;