import { useIntl } from "react-intl";
import Messages from "../../lang/Messages";
import { useEffect, useState, useContext } from "react";
import { Divider, Skeleton } from "@mui/material";
import { Product } from "../Types";
import moment from "moment";
import { ProductCard } from "../Commons/ProductCard";
import "./Selections.scss";
import { HistorySelections } from "./HistorySelections";
import { FormDataContext } from "../../App";
import { getFilteredProducts, isSameMonthYear } from "../Utils/getAPIData";

export const Selections = () => {
  const { formatMessage } = useIntl();
  const { setIsCheckout, isMinWidth800 } = useContext(FormDataContext);

  const [isWaiting, setIsWaiting] = useState<boolean>(true);
  const [currentProducts, setCurrentProducts] = useState<Product[]>([] as Product[]);
  const [historyProducts, setHistoryProducts] = useState<Product[]>([] as Product[]);

  useEffect(() => {
    setIsCheckout(false);
    const fetchProducts = async () => {
      try {
        const newProducts = await getFilteredProducts(true)
        setCurrentProducts(newProducts.filter(({ endDate }) => isSameMonthYear(endDate)))
        setHistoryProducts(newProducts.filter(({ endDate }) => !isSameMonthYear(endDate)))
        setIsWaiting(false)
      }
      catch {
        setIsWaiting(false)
      };
    }

    fetchProducts()
  }, [])

  // const isCurrentMonthYear = (endDate: string) => {
  //   return (
  //     moment().isSame(endDate, "month") && moment().isSame(endDate, "year")
  //   );
  // };

  const isAfterDate = (endDate: string) => {
    return moment().isAfter(endDate, 'month') && moment().isSameOrAfter(endDate, 'year')
  };

  const getHistoryByGroup = (currentGroup: string) => {
    return historyProducts.filter(({ categoryCode, endDate }) => categoryCode === currentGroup && isAfterDate(endDate))
  }

  return (
    <div className="selections_container container">
      <div className="selections_img">
        <h3>{formatMessage(Messages.selections_title)}</h3>
      </div>
      {/* <div className="selections__shipment">
        <Shipment setShipmentAmount={setShipmentAmount} />
      </div> */}
      {isWaiting ? Array.from({ length: 3 }, () => 0).map(_ => <Skeleton variant="rectangular" width={ window.innerWidth * 0.8} height={600} />) :
      currentProducts.map((currentProduct, index) => (
        <div className="selections">
          <div className="selections__card">
            <ProductCard item={currentProduct} />
          </div>
          {isMinWidth800 && <Divider />}
          <HistorySelections products={getHistoryByGroup(currentProduct.categoryCode)} currentIndex={index} title={formatMessage(Messages[`product_${currentProduct.categoryCode.toLowerCase()}` as keyof typeof Messages])} />
          {isMinWidth800 && !!getHistoryByGroup(currentProduct.categoryCode).length && <Divider />}
        </div>
      ))}
    </div>
  );
};
