import { useIntl } from "react-intl";
import { useContext } from "react";
import { Product } from "../Types";
import moment from "moment";
import "./HistorySelections.scss";
import { CarouselWrapper } from "../Commons/Carousel";
import { FormDataContext } from "../../App";
import Messages from "../../lang/Messages";
import "react-responsive-carousel/lib/styles/carousel.min.css";

type HistoryProps = {
  products: Product[];
  currentIndex: number;
  title: string;
  isPremium?: boolean;
};

export const HistorySelections = ({ products, title, isPremium, currentIndex }: HistoryProps) => {
  const { formatMessage } = useIntl();
  const { isMobile, isMinWidth800 } = useContext(FormDataContext);

  const getOlderSelections = () => {
    return products.sort((prod1, prod2) => (prod1.endDate >= prod2.endDate ? 1 : -1))      
  };

  const getLastTwoProductsMonth = () => {
    const prodLen = products.length
    return prodLen > 2 ? getOlderSelections().slice(prodLen - 2, prodLen) : getOlderSelections();
  }

  const getMonthText = (date: string) => {
    return moment(date).format("MMM").toLowerCase();
  };

  return (
    <>
      {!!getOlderSelections().length ? (
        <div className="history__container">
          <h3 className="history__title">{`${formatMessage(Messages.wines_history_selection)} ${title} ${formatMessage(Messages.wines_history_older)}`}</h3>
          {isMinWidth800 ? (
            <CarouselWrapper items={getOlderSelections() || ([] as Product[])} currentIndex={currentIndex} />
          ) : (
            <div className="histories">
              {getLastTwoProductsMonth().map(({ endDate, imageUrl }) => (
                <div className="history__card">
                  <img src={imageUrl} alt={imageUrl} />
                  <div className="history__month">
                    {`${formatMessage(isPremium ? Messages.wines_history_premium : Messages.wines_history_unico)} ${formatMessage(Messages[`wines_${getMonthText(endDate)}` as keyof typeof Messages])}`}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      ) : (
        <>
          {!isMinWidth800 && <div className="without__history"></div>}
        </>
      )}
    </>
  );
};
