import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import Messages from "../../lang/Messages";
import { FormDataContext } from "../../App";
import { Divider } from "@mui/material";
import "./AboutRegister.scss";

export const AboutRegister = () => {
  const { formatMessage } = useIntl();
  const { ga4Event } = useContext(FormDataContext);
  const navigate = useNavigate();

  const [contactData, setContactData] = useState<Record<string, string>>(
    {} as Record<string, string>
  );
  const [errors, setErrors] = useState<Record<string, string>>(
    {} as Record<string, string>
  );

  const handleError = (keyError: string, newValue: any, message: string) => {
    let newError = "";
    if (
      !newValue ||
      (!!newValue && typeof newValue === "string" && !newValue.trim())
    )
      newError = message;

    setErrors({ ...errors, [keyError]: newError });
  };

const handleRegistration = (isAutomatic?: Boolean) => {
  const action = isAutomatic ? 'automatic_registration' : 'manual_registration' 
  const label = isAutomatic ? 'Registro online' : 'Registro con asesor' 
  ga4Event({
    category: "Checkout",
    action,
    label
  })
  window.mt("send", "pageview", { page_title: 'AboutRegister', label, category: 'Register', action });
  isAutomatic ? navigate(`/${Messages.route_association.id}`) : window.open("https://wa.me/5491138222673")
}

  return (
    <div className="about__register__container container">
      <div className="about__register__img">
        <h3>{formatMessage(Messages.about_register_title)}</h3>
      </div>
      <div className="about__wrapper">
        <div className="about__register">
          <div className="about__register__online">
            <h3>{formatMessage(Messages.about_register_online_title)}</h3>
            <p>{formatMessage(Messages.about_register_online_text)}</p>
            <button onClick={() => handleRegistration(true)}>
              {formatMessage(Messages.about_register_online_button)}
            </button>
          </div>
          <Divider />
          <div className="about__register__manual">
            <h3>{formatMessage(Messages.about_register_manual_title)}</h3>
            <p>{formatMessage(Messages.about_register_manual_text)}</p>
            <button onClick={() => handleRegistration()}>
              {formatMessage(Messages.about_register_manual_button)}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
