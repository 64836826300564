import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { createLocality, getLocalities } from "../../services/endPoints";
import { Locality, LocalityResponse } from "../Types";
import Messages from "../../lang/Messages";
import { Localities } from "./Localities";
import { LocalityForm } from "./LocalityForm";
import { CircularProgress } from "@mui/material";
import "./LocalityAdmin.scss";

export const LocalityAdmin = () => {
  const { formatMessage } = useIntl();
  const [localities, setLocalities] = useState<LocalityResponse[]>();
  const [isWaiting, setIsWaiting] = useState<Boolean>(true);
  const [newLocality, setNewLocality] = useState<Locality>({} as Locality);
  const [isSaving, setIsSaving] = useState<Boolean>(false);

  useEffect(() => {
    getLocalities()
      .then((response) => {
        setLocalities(response.data);
        setIsWaiting(false);
      })
      .catch(() => {
        setIsWaiting(false);
      });
  }, []);

  const handleSaveLocality = () => {
    setIsSaving(true);
    createLocality(newLocality)
      .then(() => {
        setNewLocality({} as Locality);
        setIsSaving(false);
        getLocalities()
          .then((response) => {
            setLocalities(response.data);
            setIsWaiting(false);
          })
          .catch(() => {
            setIsWaiting(false);
          });
      })
      .catch(() => {
        setIsSaving(false);
      });
  };

  return isWaiting ? (
    <CircularProgress />
  ) : (
    <div className="locality__container">
      <div className="locality__title">
        <h3>{formatMessage(Messages.products_body_title)}</h3>
      </div>
      <LocalityForm
        handleSaveLocality={handleSaveLocality}
        setNewLocality={setNewLocality}
        newLocality={newLocality}
        isSaving={isSaving}
      />
      {!!localities?.length && <Localities items={localities} />}
    </div>
  );
};
