import moment from 'moment';
import { getProducts } from '../../services/endPoints';
import { PRODUCTS } from '../DEV_Products';
import { Product, ProductResponse } from '../Types';

export const isSameMonthYear = (endDate: string) => {
  return (
    moment().isSame(endDate, "month") && moment().isSame(endDate, "year")
  );
};

const getAPIData = async () => {
    const isDev = process.env.REACT_APP_ENVIRONMENT === "DEV";
    let newProducts: Product[] = [];
    if (isDev) {
      newProducts = PRODUCTS.map(
        ({
          Id,
          Name,
          Description,
          EndDate,
          ImageUrl,
          Pack,
          Plans,
          CurrentPlanId,
          CategoryCode,
          IsActive
        }) => ({
          id: Id,
          name: Name,
          description: Description,
          endDate: EndDate,
          imageUrl: ImageUrl,
          pack: JSON.parse(Pack),
          plans: Plans.map(({Id, Name, Amount, StartDate, EndDate, ExternalPlanId}) => ({ id: Id, name: Name, amount: Amount.toString(), startDate: StartDate, endDate: EndDate, externalPlanId: ExternalPlanId })),
          currentPlanId: CurrentPlanId,
          categoryCode: CategoryCode,
          isActive: IsActive
        })
      );
      return newProducts.filter(({ isActive }) => isActive)
    }

    try {
      const response = await getProducts()  
      const productResponse : ProductResponse[] = response.data
      newProducts = productResponse.map(
        ({
          Id,
          Name,
          Description,
          EndDate,
          ImageUrl,
          Pack,
          Plans,
          CurrentPlanId,
          CategoryCode,
          IsActive
        }) => ({
          id: Id,
          name: Name,
          description: Description,
          endDate: EndDate,
          imageUrl: ImageUrl,
          pack: JSON.parse(Pack),
          plans: Plans.map(({Id, Name, Amount, StartDate, EndDate, ExternalPlanId}) => ({ id: Id, name: Name, amount: Amount.toString(), startDate: StartDate, endDate: EndDate, externalPlanId: ExternalPlanId })),
          currentPlanId: CurrentPlanId,
          categoryCode: CategoryCode,
          isActive: IsActive
        })
      );
      
      return newProducts.filter(({ isActive }) => isActive)
    } catch (error) {
      throw new Error('Service does not respond')
    }
}

export const getFilteredProducts = async (allProducts?: boolean) => {
  const newProducts: Product[] = await getAPIData();
  const filteredProducts = allProducts ? newProducts : newProducts.filter(({ endDate }) => isSameMonthYear(endDate));
  return filteredProducts.map(product => {
    const filteredPlan = product.plans.find(({ startDate, endDate }) => moment(startDate).isSameOrBefore(moment()) && moment(endDate).isAfter(moment()))
    console.log("FILTERED", filteredPlan)
    //.sort((plan1, plan2) => moment(plan1.startDate).isAfter(plan2.startDate) ? 1 : -1)
     return { ...product, amount: parseInt(filteredPlan?.amount || "0") }        
    })
}