import { useIntl } from "react-intl";
import Messages from "../../lang/Messages";
import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Plan } from "../Types";
import { useContext } from "react";
import { AddCircleOutlined, RemoveCircleOutline } from "@mui/icons-material";
import BlockIcon from '@mui/icons-material/Block';
import moment from "moment-timezone";
import { FormDataContext } from "../../App";
import "./PriceForm.scss"

type ProductFormProps = {
  currentPrice?: Plan;
  handleDelete?: (index: number) => void;
  errors: Record<string, string>;
  handleError: (keyError: string, newValue: any, message: string) => void;
  handleAddPrice: (value: Plan) => void;
  newPrice?: Plan;
  handlePrices: (key: string, newValue: string | number | null) => void;
  handlePriceDelete?: (id: number) => void;
  isNew?: boolean;
};

export const PriceForm = ({
  currentPrice,
  errors,
  handleAddPrice,
  handleError,
  newPrice,
  handlePrices,
  handlePriceDelete,
  isNew
}: ProductFormProps) => {
  const { formatMessage } = useIntl();
  const { isMinWidth800 } = useContext(FormDataContext);

  const { id, amount, startDate, endDate } = currentPrice || {} as Plan
  const isLoadedPrice = amount && handlePriceDelete;

  moment.tz.setDefault('GMT');

  const formIsValid = () => {
    const errorsAux =
      Object.keys(errors).filter(
        (key) => errors[key].trim().length > 0
      ) || [];

    return errorsAux.length === 0;
  };

  return (
    <div className="productadmin__prices">
    {/* <TextField
        fullWidth
        label={formatMessage(Messages.product_selection_listPrice)}
        variant="outlined"
        error={!!errors.listPriceError}
        value={newProduct.listPrice || 0}
        onChange={(event) => {
        const { value } = event.target;
        handleSelectionChange("listPrice", value);
        handleError(
            "listPriceError",
            value,
            formatMessage(Messages.product_selection_price_error)
        );
        }}
        onBlur={(event) => {
        const { value } = event.target;
        handleError(
            "listPriceError",
            value,
            formatMessage(Messages.product_selection_price_error)
        );
        }}
    /> */}
    <TextField
        label={formatMessage(Messages.product_selection_memberPrice)}
        variant="outlined"
        error={!!errors.memberPriceError}
        value={amount || newPrice?.amount || 0}
        //disabled={moment().isSameOrAfter(startDate) && !isNew}
        disabled={!isNew}
        onChange={(event) => {
            const { value } = event.target;
            handlePrices("amount", value);
            handleError("memberPriceError", value, formatMessage(Messages.product_selection_price_error))}}
        onBlur={(event) => {
            const { value } = event.target;
            handleError("memberPriceError", value, formatMessage(Messages.product_selection_price_error));
        }} />
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'fr'}>
        {startDate ? <DatePicker
          label={formatMessage(Messages.product_price_startDate)}
          value={moment(startDate)}
          format='DD/MM/YYYY'
          minDate={moment()}
          disabled={!isNew}
          onChange={(date) => { handlePrices("startDate", moment(date).format('YYYY/MM/DD'))
              handleError("startDateError", date, formatMessage(Messages.product_price_startDate_error));
        }} /> :
        <DatePicker
          label={formatMessage(Messages.product_price_startDate)}
          format='DD/MM/YYYY'
          minDate={moment()}
          disabled={!isNew}
          onChange={(date) => { handlePrices("startDate", moment(date).format('YYYY/MM/DD'))
              handleError("startDateError", date, formatMessage(Messages.product_price_startDate_error));
        }} />}
    </LocalizationProvider>
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'fr'}>
        {endDate ? <DatePicker
          label={formatMessage(Messages.product_price_endDate)}
          value={moment(endDate)}
          format='DD/MM/YYYY'
          minDate={moment(startDate) || moment()}
          disabled={!isNew}
          onChange={(date) => {
              handlePrices("endDate", moment(date).format('YYYY/MM/DD'));
              handleError("endDateError", date, formatMessage(Messages.product_price_endDate_error));
        }} /> :
        <DatePicker
          label={formatMessage(Messages.product_price_endDate)}
          format='DD/MM/YYYY'
          minDate={moment(startDate) || moment()}
          disabled={!isNew}
          onChange={(date) => {
              handlePrices("endDate", moment(date).format('YYYY/MM/DD'));
              handleError("endDateError", date, formatMessage(Messages.product_price_endDate_error));
        }} />}
    </LocalizationProvider>
    {isLoadedPrice ? (
      <>
        {moment().isBefore(startDate) ? <RemoveCircleOutline onClick={() => handlePriceDelete(id)} /> : <BlockIcon />}
      </>
      ) : (
        <>
          {isMinWidth800 ? (
            <button className="packform__add" disabled={!formIsValid()} onClick={() => handleAddPrice(newPrice || {} as Plan)}>
              {formatMessage(Messages.pack_add_button)}
            </button>
          ) : (
            <AddCircleOutlined onClick={() => handleAddPrice(newPrice || {} as Plan)} />
          )}
        </>
      )}
      {errors.price && !isLoadedPrice && <p>{errors.price}</p>}
    </div>);
};
