import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import "./ProductsTable.scss"
import { useContext, useEffect, useMemo, useState } from 'react';
import { Plan, Product } from '../Types';
import { FormDataContext } from '../../App';
import { CircularProgress } from '@mui/material';
import moment from 'moment';
import Messages from '../../lang/Messages';
import { useIntl } from 'react-intl';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { getFilteredProducts } from '../Utils/getAPIData';
import SyncIcon from '@mui/icons-material/Sync';
import { syncProducts } from '../../services/endPoints'


interface TableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Product) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Product | string;
    label: string;
    numeric: boolean;
}

interface TableToolbarProps {
  numSelected: number;
  handleDelete: () => void;
}

interface ProductsTableProps {
  handleDeleteProducts: (ids: readonly number[]) => void;
}

const descendingComparator = <T,>(a: T, b: T, orderBy: keyof T) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

const getComparator = <Key extends keyof any>(
  order: Order,
  orderBy: Key,
): ((
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const stableSort = <T,>(array: readonly Product[], comparator: (a: T, b: T) => number) => {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  console.log("STABILIZED", array)
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Nombre',
  },
//   {
//     id: 'description',
//     numeric: false,
//     disablePadding: false,
//     label: 'Descripción',
//   },
  {
    id: 'categoryCode',
    numeric: false,
    disablePadding: false,
    label: 'Categoría',
  },
  {
    id: 'endDate',
    numeric: true,
    disablePadding: false,
    label: 'Fecha fin',
  },
  {
    id: 'price',
    numeric: true,
    disablePadding: false,
    label: 'Precio socio',
  },
];

const TableHeader = (props: TableProps) => {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler =
    (property: keyof Product) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id as keyof Product)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const TableToolbar = (props: TableToolbarProps) => {
  const { numSelected, handleDelete } = props;
  const navigate = useNavigate()

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div">
          Productos
        </Typography>
      )}
      <Tooltip title="Sync">
        <IconButton>
          <SyncIcon onClick={() => syncProducts()}/>
        </IconButton>
      </Tooltip>
      <Tooltip title="Add">
        <IconButton>
          <AddIcon onClick={() => navigate(`/${Messages.route_prod_manage.id}`)}/>
        </IconButton>
      </Tooltip>
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon onClick={handleDelete}/>
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}
export const ProductsTable = (props: ProductsTableProps) => {
  const { setIsCheckout } = useContext(FormDataContext);
  const { formatMessage } = useIntl();  
  const navigate = useNavigate()
  const { formattedNumber } = useContext(FormDataContext);

  const { handleDeleteProducts } = props
  
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Product>('name');
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isWaiting, setIsWaiting] = useState<boolean>(true);
  const [products, setProducts] = useState<Product[]>([] as Product[]);

  useEffect(() => {
    setIsCheckout(false);
    const fetchProducts = async () => {
      try {
        const newProducts = await getFilteredProducts(true)
        setProducts(newProducts.filter(product => product.isActive))
        setIsWaiting(false)
      }
      catch {
        setIsWaiting(false)
      };
    }

    fetchProducts()
  }, [])

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Product,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = products.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - products.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(products, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage],
  );

  const handleDelete = () => {
    handleDeleteProducts(selected)
    setProducts(products.filter(({ id }) => !selected.includes(id)))
  }

  const getFormattedPrice = (plans: Plan[], currentPlandId: number) => {
    const currentPrice = plans.find(({ id }) => id === currentPlandId)?.amount || "0"
    return formattedNumber.format(parseInt(currentPrice));
  }

  return (
    <Box sx={{ width: '100%' }}>
      {isWaiting ? <CircularProgress /> :
      <Paper className='products__container'>
        <TableToolbar numSelected={selected.length} handleDelete={handleDelete} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}>
            <TableHeader
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={products.length}
            />
            <TableBody>
              {products.map((product, index) => {
                const isItemSelected = isSelected(product.id as number);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow hover
                    onClick={(event) => handleClick(event, product.id as number)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={product.id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}>
                    <TableCell padding="checkbox">
                      <Checkbox color="primary" checked={isItemSelected} inputProps={{'aria-labelledby': labelId}} />
                    </TableCell>
                    <TableCell component="th" id={labelId} scope="row" padding="none">
                      {product.name}
                    </TableCell>
                    {/* <TableCell align="right">{product.description}</TableCell> */}
                    <TableCell align="left">{formatMessage(Messages[`product_${product.categoryCode.toLowerCase()}` as keyof typeof Messages])}</TableCell>
                    <TableCell align="right">{moment(product.endDate).format("DD/MM/YYYY")}</TableCell>
                    <TableCell align="right">{formattedNumber.format(product?.amount || 0)}</TableCell>
                    <TableCell align="right"><EditIcon onClick={() => navigate(`/${Messages.route_prod_manage.id}`, { state: product })}/></TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={products.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>}
    </Box>
  );
}