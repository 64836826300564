import { useIntl } from "react-intl";
import Messages from "../../lang/Messages";
import { Autocomplete, Input, InputAdornment, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Plan, Product } from "../Types";
import { ChangeEvent } from "react";
import { AttachFile } from "@mui/icons-material";
import moment from "moment";
import { PriceForm } from "./PriceForm";

const CATEGORY_CODES = [
  {
    categoryCode: 'UN',
    meaning: 'Único'
  },
  {
    categoryCode: 'PR',
    meaning: 'Premium'
  },
  {
    categoryCode: 'DG',
    meaning: 'Degustación'
  },
  {
    categoryCode: 'BL',
    meaning: 'Blancos'
  },
]

type ProductFormProps = {
  newProduct: Product;
  errors: Record<string, string>;
  handleError: (keyError: string, newValue: any, message: string) => void;
  handleSelectionChange: (key: string, value: string) => void;
  handleAddPrice: (value: Plan) => void;
  handleImageUpload: (event: ChangeEvent<HTMLInputElement>) => void;
  newPrice?: Plan;
  handlePrices: (key: string, newValue: string | number | null) => void;
  handlePriceDelete: (id: number) => void;
};

export const ProductForm = ({
  newProduct,
  errors,
  handleSelectionChange,
  handleAddPrice,
  handleImageUpload,
  handleError,
  newPrice,
  handlePrices,
  handlePriceDelete,
}: ProductFormProps) => {
  const { formatMessage } = useIntl();

  console.log("PRODUCT!!", newProduct)

  return (
    <div className="productadmin__form">
      <TextField
        fullWidth
        label={formatMessage(Messages.product_selection_name)}
        variant="outlined"
        error={!!errors.nameError}
        value={newProduct.name}
        onChange={(event) => {
          const { value } = event.target;
          handleSelectionChange("name", value);
          handleError(
            "nameError",
            value,
            formatMessage(Messages.product_selection_name_error)
          );
        }}
        onBlur={(event) => {
          const { value } = event.target;
          handleError(
            "nameError",
            value,
            formatMessage(Messages.product_selection_name_error)
          );
        }}
      />
      <Autocomplete
        id="wine-category"
        options={CATEGORY_CODES}
        autoHighlight
        getOptionLabel={(option) => option.meaning}
        value={CATEGORY_CODES.find(({ categoryCode }) => categoryCode === newProduct.categoryCode)}
        onChange={(_, newValue) => {
          handleSelectionChange("categoryCode", newValue?.categoryCode || '');
          handleError(
            "categoryError",
            newValue,
            formatMessage(Messages.product_category_error)
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={formatMessage(Messages.product_selection_category)}
            error={!!errors.typeError}
            onBlur={(event) => {
              const { value } = event.target;
              handleError(
                "typeError",
                value,
                formatMessage(Messages.pack_type_error)
              );
            }}
          />
        )}
      />
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'fr'}>
        <DatePicker
          label={formatMessage(Messages.product_selection_endDate)}
          value={moment(newProduct.endDate)}
          format='DD/MM/YYYY'
          onChange={(date) => {
            handleSelectionChange("endDate", moment(date).format('YYYY/MM/DD'));
            handleError(
              "endDateError",
              date,
              formatMessage(Messages.product_selection_endDate_error)
            );
          }}
        />
      </LocalizationProvider>
      <Input
        type="file"
        id="image-upload"
        name="image"
        inputProps={{ accept: "image/*" }}
        onChange={handleImageUpload}
        className="input__image"
        endAdornment={
          <InputAdornment position="end">
            <label htmlFor="image-upload">
              <AttachFile />
            </label>
          </InputAdornment>
        } />
      <div className="product__desc">
        <TextField
          fullWidth
          multiline
          rows={4}
          id="description-input"
          label={formatMessage(Messages.product_selection_description)}
          variant="outlined"
          value={newProduct.description || ''}
          onChange={(event) => {
            const { value } = event.target;
            handleSelectionChange("description", value);
          }} />
      </div>
      <PriceForm errors={errors} handleAddPrice={handleAddPrice} handleError={handleError} handlePrices={handlePrices} newPrice={newPrice} isNew={true}/>
      <h3>{formatMessage(Messages.product_selection_price_detail)}</h3>
      {newProduct.plans && newProduct.plans.filter((plan) => !plan.delete).sort((price1, price2) => moment(price1.startDate).isBefore(price2.startDate) ? -1 : 1).map((price) => (
              <PriceForm currentPrice={price} errors={errors} handleAddPrice={handleAddPrice} handleError={handleError} handlePriceDelete={handlePriceDelete} handlePrices={handlePrices} />
            ))}
    </div>
  );
};
