import { createElement, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Messages from "../../lang/Messages";
import { CircularProgress, TextField } from "@mui/material";
import { AreaResponse, StepComponent } from "../Types";
import "./FormBuilder.scss";
import _ from "lodash";
import { AxiosResponse } from "axios";

export const FormBuilder = ({
  handleFormData,
  handleError,
  errors,
  formData,
  formFields,
  cities
}: StepComponent) => {
  const { formatMessage } = useIntl();

  const [locationsData, setLocationsData] = useState<Record<string, string[]>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getOptions = formFields?.find(({ options }) => options)?.options
    if (getOptions) {
        setIsLoading(true);
        getOptions()
          .then(({ data }: AxiosResponse) => {
            setIsLoading(false);
            const { provincias }: AreaResponse = data;
            setLocationsData({ provinces: provincias.map(({ nombre }) => nombre) });
          })
          .catch(() => {
            setIsLoading(false);
          });
      }
    }, []);

  let groupComponents: React.ReactNode[] = [];
  
  if (!handleFormData || !handleError || !errors || !formData)
    return <></>;

  return isLoading ? <CircularProgress />
    : <div className="form__container">
      {formFields?.map(({ id, label, component, type, error, helperText, required, additionalAttributes, options }, index) => {
          const { parentClassName , className } = additionalAttributes ?? {};

          const isFinalElementGroup: boolean = !!parentClassName && ((index + 1 <= formFields.length - 1 && parentClassName !== formFields[index + 1].additionalAttributes?.parentClassName) || index === formFields.length - 1);
          const optionsKey = additionalAttributes?.options || [];

          const handleChange = (
            event: React.ChangeEvent<HTMLInputElement> | string,
            id: string,
            error: string | undefined,
            required: boolean
          ) => {
            let value;
            value = typeof event === "string" ? event : event.target.value;
            handleFormData(id, value);
            if (required) handleError(`${id}Error`, value, error || "");
          };

          const renderInput = (
            params: any,
            id: string,
            error: string | undefined,
            helperText: string,
            label: string
          ) => (
            <TextField
              {...params}
              label={label}
              error={!!errors[`${id}Error`]}
              helperText={helperText}
              onBlur={(event) => {
                const { value } = event.target;
                handleError(`${id}Error`, value, error || "");
              }} />
          );

          if (parentClassName)
            groupComponents.push(
              createElement(component, {
                id,
                label,
                type,
                error: !!errors[`${id}Error`],
                helperText,
                required,
                className,
                value: formData[id],
                ...additionalAttributes,
                renderInput: (params: any) =>
                  renderInput(params, id, error, helperText || '', label),
                onChange: type
                  ? (event: React.ChangeEvent<HTMLInputElement>) =>
                      handleChange(event, id, error, required)
                  : (_: any, event: React.ChangeEvent<HTMLInputElement>) =>
                      handleChange(event, id, error, required),
                options: options ? locationsData[optionsKey] || [] : cities,
                getOptionLabel: (option: any) => option,
              })
            );

          if (isFinalElementGroup) 
            return <div id={id} className={parentClassName}>{groupComponents}</div>;
            

          if (!parentClassName) {
            groupComponents = [];
            return createElement(component, {
              id,
              label,
              type,
              error: !!errors[`${id}Error`],
              helperText,
              required,
              className,
              value: formData[id],
              ...additionalAttributes,
              renderInput: (params: any) =>
                renderInput(params, id, error, helperText || '', label),
              onChange: type
                ? (event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(event, id, error, required)
                : (_: any, event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(event, id, error, required),
              options: locationsData[optionsKey] || [],
              getOptionLabel: (option: any) => option,
            });
          }
        }
      )}
    </div>;
};
